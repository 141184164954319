import { FC } from 'react';
import {
  BulkDeleteWithConfirmButton,
  BulkDeleteWithConfirmButtonProps,
} from 'react-admin';

export const MoonstarDatagridBulkActions: FC<
  Omit<BulkDeleteWithConfirmButtonProps, 'mutationMode'>
> = ({ ...props }) => {
  return <BulkDeleteWithConfirmButton mutationMode="pessimistic" {...props} />;
};
