import { Box } from '@mui/material';
import type { GroupChannel } from '@sendbird/chat/groupChannel';
import { GroupChannelProvider } from '@sendbird/uikit-react/GroupChannel/context';
import { useState } from 'react';
import { useSidebarState, useTranslate } from 'react-admin';
import { useFetchSendBirdAppId } from '../../../api/hooks/useFetchSendBirdAppId';
import { AppLoading } from '../../AppLoading';
import { sendBirdColorSet } from './colorSet';
import { ForwardMessageProvider } from './ForwardMessageProvider';
import { GroupCustomChannel } from './GroupCustomChannel/GroupCustomChannel';
import { GroupCustomChannelList } from './GroupCustomChannelList/GroupCustomChannelsList';
import { GroupCustomChannelSettings } from './GroupCustomChannelSettings/GroupCustomChannelSettings';
import { GroupCustomMessageSearch } from './GroupCustomMessageSearch/GroupCustomMessageSearch';
import { ConfirmDiscardChannelModal } from './ConfirmDiscardChannelModal';
import { MoonstarHelmet } from '../Moonstar/MoonstarHelmet';
import { useGetTotalUnreadMessageCount } from '../../../api/hooks/sendbird/useGetTotalUnreadMessageCount';

// TODO: Rename to 'Groups' and remove current 'Groups' after all the work for custom web chat is done
export const GroupsCustom = () => {
  const { isPending: isSendBirdAppPending } = useFetchSendBirdAppId();
  const [currentChannel, setCurrentChannel] = useState<GroupChannel | null>();
  const [targettedSelectedChannel, setTargettedSelectedChannel] =
    useState<GroupChannel | null>();
  const [showSearch, setShowSearch] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [_, setOpen] = useSidebarState();
  const [openConfirmDiscardChannel, setOpenConfirmDiscardChannel] =
    useState(false);
  const { data: unreadCount } = useGetTotalUnreadMessageCount();
  const translate = useTranslate();
  const appName = translate('moonstar.appName');

  const handleSetShowSearch = (show: boolean) => {
    setShowSearch(show);
    if (show) {
      setShowSettings(false);
    }
  };

  const handleSetShowSettings = (show: boolean) => {
    setShowSettings(show);
    if (show) {
      setShowSearch(false);
    }
  };

  const handleChannelCreated = (channel: GroupChannel) => {
    setCurrentChannel(channel);
  };

  const handleDiscardChannel = () => {
    if (targettedSelectedChannel) {
      setCurrentChannel(targettedSelectedChannel);
    }
    setOpenConfirmDiscardChannel(false);
  };

  const handleClose = () => {
    setOpenConfirmDiscardChannel(false);
  };

  if (isSendBirdAppPending) {
    return <AppLoading />;
  }

  return (
    <>
      <MoonstarHelmet>
        <title>
          {unreadCount ? `(${unreadCount}) ` : ''}Chat | {appName}
        </title>
      </MoonstarHelmet>
      <ForwardMessageProvider>
        <Box
          sx={{
            display: 'flex',
            height: '100%',
            ...sendBirdColorSet,
          }}
        >
          <GroupCustomChannelList
            onChannelSelect={(channel) => {
              // Block moving from channel to channel if there are no messages in the current one
              if (currentChannel && !currentChannel.lastMessage) {
                setTargettedSelectedChannel(channel);
                setOpenConfirmDiscardChannel(true);
                return;
              }
              // If there are messages in the current channel, just switch to the new one
              setCurrentChannel(channel);
            }}
            onChannelCreated={handleChannelCreated}
            selectedChannelUrl={currentChannel?.url}
          />

          {currentChannel && (
            <GroupChannelProvider channelUrl={currentChannel.url}>
              {/* Current channel display */}

              <GroupCustomChannel
                channelUrl={currentChannel.url}
                onChatHeaderActionClick={() => {
                  handleSetShowSettings(true);
                  setOpen(false);
                }}
                onSearchClick={() => {
                  handleSetShowSearch(true);
                  setOpen(false);
                }}
              />

              {/* Side Panel for Settings */}
              {showSettings && !showSearch && (
                <GroupCustomChannelSettings
                  channelUrl={currentChannel.url}
                  onCloseClick={() => handleSetShowSettings(false)}
                />
              )}

              {/* Side Panel for Search */}
              {showSearch && !showSettings && (
                <GroupCustomMessageSearch
                  channelUrl={currentChannel.url}
                  onCloseClick={() => handleSetShowSearch(false)}
                />
              )}
            </GroupChannelProvider>
          )}
        </Box>
      </ForwardMessageProvider>

      <ConfirmDiscardChannelModal
        open={openConfirmDiscardChannel}
        onClose={handleClose}
        onDiscard={handleDiscardChannel}
      />
    </>
  );
};
