import { regex } from 'react-admin';
import { FileSize, toBytes } from './toBytes';
import {
  fileInputPendingUpload,
  ReactAdminFile,
} from '../api/transformHooks/fileUpload';

export const phoneNumber = () =>
  regex(/^\+\d{1,15}$/, 'moonstar.validation.phoneNumber');

export const requiredIf =
  <T>(check: (value: T) => boolean, message?: string) =>
  (_field: unknown, allValues: T) =>
    check(allValues) ? message ?? 'Required' : undefined;

type BlankFieldsSomeOf = {
  /**
   * If any of these fields are blank, show a "Required" error message
   */
  someFields: string[];
};
type BlankFieldsAllOf = {
  /**
   * If all of these fields are blank, show a "Required" error message
   */
  everyField: string[];
};
/**
 * If this field is blank, show a "Required" error message
 */
type BlankFieldsField = string;
type BlankFields = BlankFieldsSomeOf | BlankFieldsAllOf | BlankFieldsField;

export const requiredIfFieldsAreBlank = (
  fields: BlankFields,
  message?: string
) =>
  requiredIf<Record<string, unknown>>((value) => {
    if (typeof fields === 'string') {
      return !value[fields];
    } else if ('everyField' in fields) {
      return fields.everyField.every((key) => !value[key]);
    } else {
      return fields.someFields.some((key) => !value[key]);
    }
  }, message);

export const maxFileSize = (maxSize: FileSize) => {
  const maxSizeBytes = toBytes(maxSize);

  return (value: ReactAdminFile) => {
    if (!fileInputPendingUpload(value)) {
      return undefined;
    }

    if (value.rawFile.size > maxSizeBytes) {
      return {
        message: 'moonstar.validation.maxFileSize',
        args: { maxSize },
      };
    }

    return undefined;
  };
};
