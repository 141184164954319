import {
  Datagrid,
  DateField,
  FunctionField,
  ReferenceField,
  TextField,
} from 'react-admin';
import { ResourcesValue } from '../AdminResources';

export const BadgesAwardsList = () => {
  return (
    <Datagrid
      rowClick={false}
      bulkActionButtons={false}
      style={{ marginTop: 16 }}
    >
      <ReferenceField
        source="awardedToUserId"
        reference={ResourcesValue.USERS}
        label="Awarded to"
        sortable={false}
      >
        <FunctionField
          render={(record: { firstName: any; lastName: any }) =>
            `${record.firstName} ${record.lastName} `
          }
        />
      </ReferenceField>

      <TextField sortable={false} source="awardedToUnit" />

      <ReferenceField
        source="badgeId"
        reference={ResourcesValue.BADGES}
        sortable={false}
      >
        <FunctionField render={(record: { name: any }) => `${record.name} `} />
      </ReferenceField>

      <ReferenceField
        source="awardedByUserId"
        reference={ResourcesValue.USERS}
        label="Awarded by"
        sortable={false}
      >
        <FunctionField
          render={(record: { firstName: any; lastName: any }) =>
            `${record.firstName} ${record.lastName} `
          }
        />
      </ReferenceField>

      <TextField sortable={false} source="awardedByUnit" />

      <DateField
        source="awardedOn"
        showTime={true}
        options={{
          dateStyle: 'medium',
          timeStyle: 'short',
        }}
      />
    </Datagrid>
  );
};
