import { useRecordContext } from 'react-admin';
import { Avatar, Box, styled, Typography } from '@mui/material';
import { Badge } from './types';

export const BadgeOptionRenderer = () => {
  const record = useRecordContext<Badge>();

  if (!record) {
    return null;
  }

  return (
    <BadgeOptionContainer>
      <BadgeOptionAvatar src={record.imageUrl} />
      <Typography>{record.name}</Typography>
    </BadgeOptionContainer>
  );
};

const BadgeOptionContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0.3),
}));

const BadgeOptionAvatar = styled(Avatar)(({ theme }) => ({
  marginRight: theme.spacing(1.5),
}));
