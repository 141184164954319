import GroupChannelListUI, {
  GroupChannelListUIProps,
} from '@sendbird/uikit-react/GroupChannelList/components/GroupChannelListUI';
import { useGroupChannelListContext } from '@sendbird/uikit-react/GroupChannelList/context';
import { useEffect } from 'react';
import { useForwardMessageContext } from '../ForwardMessageProvider';

export const GroupCustomChannelListUI = ({
  renderHeader,
}: GroupChannelListUIProps) => {
  const groupChannelListContext = useGroupChannelListContext();
  const shareMessageContext = useForwardMessageContext();

  useEffect(() => {
    if (groupChannelListContext.initialized) {
      shareMessageContext.setGroupChannels(
        groupChannelListContext.groupChannels
      );
    }
  }, [
    groupChannelListContext.groupChannels,
    groupChannelListContext.initialized,
    shareMessageContext,
  ]);

  return <GroupChannelListUI renderHeader={renderHeader} />;
};
