import {
  Button,
  ImageField,
  ImageInput,
  TextInput,
  TopToolbar,
  required,
} from 'react-admin';
import { useNavigate, useParams } from 'react-router-dom';
import { MoonstarEdit } from '../Moonstar/MoonstarEdit';
import { MoonstarEditForm } from '../Moonstar/MoonstarEditForm';
import { MoonstarEditFormToolbar } from '../Moonstar/MoonstarEditFormToolbar';
import { SimpleStack } from '../Moonstar/SimpleStack';
import { ResourcesValue } from '../AdminResources';
import { maxFileSize } from '../../../utils/validation';

const imageValidation = maxFileSize('5MB');

export const BadgesEdit = () => (
  <MoonstarEdit redirect="list" actions={<BadgesEditActions />}>
    <MoonstarEditForm toolbar={<MoonstarEditFormToolbar hideDelete />}>
      <SimpleStack>
        <TextInput source="name" validate={required()} />
        <ImageInput
          source="image"
          accept={{ 'image/*': ['.jpg', '.jpeg', '.png', '.gif'] }}
          sx={{ alignItems: 'start' }}
          label="Badge image"
          validate={imageValidation}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
      </SimpleStack>
    </MoonstarEditForm>
  </MoonstarEdit>
);

const BadgesEditActions = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  return (
    <TopToolbar>
      <Button
        sx={{ marginRight: 'auto' }}
        label="Analytics"
        onClick={() =>
          navigate(`/${ResourcesValue.BADGE_AWARDS}/analytics/${id}`)
        }
      />
    </TopToolbar>
  );
};
