import { BooleanField, Datagrid, DateField, NumberField } from 'react-admin';
import { MoonstarList } from '../../Moonstar/MoonstarList';
import { useParams } from 'react-router-dom';
import { growLastColumn } from '../../../../utils/growLastColumn';

export const NudgeFeedbackList = () => {
  const { nudgeId } = useParams();

  return (
    <MoonstarList
      actions={false}
      filter={{ nudgeId }}
      sort={{ field: 'createdOn', order: 'DESC' }}
      sx={{ mt: '3em' }}
    >
      <Datagrid bulkActionButtons={false} sx={growLastColumn}>
        <NumberField source="rating" />
        <BooleanField source="isInteresting" />
        <DateField source="createdOn" />
      </Datagrid>
    </MoonstarList>
  );
};
