import {
  Button,
  ChipField,
  Datagrid,
  ImageField,
  Link,
  NumberField,
  ReferenceArrayField,
  SingleFieldList,
  TextField,
  useRecordContext,
} from 'react-admin';
import { MoonstarList } from '../Moonstar/MoonstarList';
import CollectionsIcon from '@mui/icons-material/Collections';
import CommentIcon from '@mui/icons-material/Comment';
import {
  shrinkFirstColumn,
  shrinkLastColumn,
} from '../../../utils/shrinkLastColumn';

export const NudgeList = () => {
  return (
    <MoonstarList>
      <Datagrid
        rowClick="edit"
        bulkActionButtons={false}
        sx={[shrinkLastColumn, shrinkFirstColumn]}
      >
        <ImageField source="image.src" title="image.title" />
        <TextField source="title" />
        <TextField source="description" />
        <TextField source="hintDescription" />
        <NumberField source="duration" />
        <ReferenceArrayField
          source="categoryIds"
          reference="nudgeCategories"
          sortable={false}
        >
          <SingleFieldList linkType={false}>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <ReferenceArrayField
          source="cohortIds"
          reference="cohorts"
          sortable={false}
        >
          <SingleFieldList linkType={false} empty={<p>No cohorts assigned</p>}>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <ViewNudgeSlidesButton />
      </Datagrid>
    </MoonstarList>
  );
};

const ViewNudgeSlidesButton = () => {
  const record = useRecordContext();

  return (
    // Fixes `Button` propagating the link up to the table and navigating twice
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div onClick={(e) => e.stopPropagation()}>
      <Button
        component={Link}
        to={`/nudges/${record?.id}/slides`}
        startIcon={<CollectionsIcon />}
        label="resources.nudges.fields.nudgeSlides"
      />
      <Button
        component={Link}
        to={`/nudges/${record?.id}/feedbacks`}
        startIcon={<CommentIcon />}
        label="resources.nudges.fields.nudgeFeedbacks"
      />
    </div>
  );
};
