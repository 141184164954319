import { HttpClient } from '../httpClient';

export const setUsersEnabled =
  (httpClient: HttpClient) =>
  async (request: { userIds: number[]; isEnabled: boolean }) => {
    const successfulUserIds: number[] = [];
    const failedUserIds: number[] = [];

    const promises = request.userIds.map(async (userId) => {
      const body = {
        isEnabled: request.isEnabled,
      };

      await httpClient
        .api(`/admin/users/${userId}/enabled`, {
          method: 'PUT',
          body: JSON.stringify(body),
        })
        .then(() => {
          successfulUserIds.push(userId);
        })
        .catch(() => {
          failedUserIds.push(userId);
        });
    });

    await Promise.all(promises);

    return {
      successfulUserIds,
      failedUserIds,
    };
  };
