import {
  AutocompleteArrayInput,
  NullableBooleanInput,
  ReferenceArrayInput,
  SearchInput,
} from 'react-admin';
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment } from '@mui/material';

const filterToQueryWithName = (searchText: string) => ({
  name: `%${searchText}%`,
});

export const BasicUserFilters = [
  <SearchInput
    source="q"
    alwaysOn
    sx={{ marginY: 0 }}
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <SearchIcon color="disabled" />
        </InputAdornment>
      ),
    }}
  />,

  <ReferenceArrayInput
    label="Department"
    source="departmentId"
    reference="departments"
    perPage={999}
    sort={{ field: 'name', order: 'ASC' }}
    alwaysOn
  >
    <AutocompleteArrayInput
      margin="none"
      optionText="name"
      filterToQuery={filterToQueryWithName}
    />
  </ReferenceArrayInput>,

  <ReferenceArrayInput
    label="Job Title"
    source="jobTitleId"
    reference="jobTitles"
    perPage={999}
    sort={{ field: 'name', order: 'ASC' }}
    alwaysOn
  >
    <AutocompleteArrayInput
      margin="none"
      optionText="name"
      filterToQuery={filterToQueryWithName}
    />
  </ReferenceArrayInput>,

  <ReferenceArrayInput
    label="Region"
    source="regionId"
    reference="regions"
    perPage={999}
    sort={{ field: 'name', order: 'ASC' }}
    alwaysOn
  >
    <AutocompleteArrayInput
      margin="none"
      optionText="name"
      filterToQuery={filterToQueryWithName}
    />
  </ReferenceArrayInput>,

  <ReferenceArrayInput
    label="Units"
    source="unitIds"
    reference="units"
    perPage={999}
    sort={{ field: 'name', order: 'ASC' }}
    alwaysOn
  >
    <AutocompleteArrayInput
      margin="none"
      optionText="name"
      filterToQuery={filterToQueryWithName}
    />
  </ReferenceArrayInput>,
];

export const UserFilters = [
  ...BasicUserFilters,

  <NullableBooleanInput
    label="moonstar.user.filter.userIsDisabled.label"
    trueLabel="moonstar.user.filter.userIsDisabled.trueLabel"
    falseLabel="moonstar.user.filter.userIsDisabled.falseLabel"
    nullLabel="moonstar.user.filter.userIsDisabled.nullLabel"
    source="userIsDisabled"
    SelectProps={{
      displayEmpty: true,
    }}
    InputLabelProps={{
      shrink: true,
    }}
    sx={{ marginY: 0 }}
    alwaysOn
  />,
];
