import { GroupChannelListProps } from '@sendbird/uikit-react/GroupChannelList';
import { GroupChannelListCustomHeader } from './GroupChannelListCustomHeader';
import { CreateChannelProvider } from '@sendbird/uikit-react/CreateChannel/context';
import { GroupChannelListProvider } from '@sendbird/uikit-react/GroupChannelList/context';
import { GroupCustomChannelListUI } from './GroupCustomChannelListUI';

export const GroupCustomChannelList = (props: GroupChannelListProps) => {
  return (
    <CreateChannelProvider onChannelCreated={props.onChannelCreated}>
      <GroupChannelListProvider {...props}>
        <GroupCustomChannelListUI
          renderHeader={() => <GroupChannelListCustomHeader />}
        />
      </GroupChannelListProvider>
    </CreateChannelProvider>
  );
};
