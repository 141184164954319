import { ResourcesValue } from '../../components/admin/AdminResources';
import { Badge } from '../../components/admin/Badges/types';
import { getTitleFromUrl } from '../../utils/getTitleFromUrl';
import { LifecycleHooks } from '../addLifecycleHooks';

type TransformedBadge = {
  id: number;
  name: string;
  image?: {
    src: string;
    title: string;
  };
};

type CreateBadge = {
  id: number;
  name: string;
  imageUrl?: string;
};

export const badgesDataTransform: LifecycleHooks[] = [
  {
    resource: ResourcesValue.BADGES,
    afterGetOne: (badge) => {
      const badgeData: Badge = badge.data;

      // Mapping to adjust to react-admin ImageInput and ImageField in BadgesEdit
      if (badgeData.imageUrl) {
        badge.data = {
          id: badgeData.id,
          name: badgeData.name,
          image: {
            src: badgeData.imageUrl,
            title: getTitleFromUrl(badgeData.imageUrl),
          },
        };
      }

      return Promise.resolve(badge);
    },
    beforeSave: (transformedBadge: TransformedBadge) => {
      // Mapping here so it doesn't have to be done on BE
      const badge: CreateBadge = {
        id: transformedBadge.id,
        name: transformedBadge.name,
        imageUrl: transformedBadge.image
          ? transformedBadge.image.src
          : undefined,
      };

      return Promise.resolve(badge);
    },
  },
];
