import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { Card, CardContent, CardHeader, useTheme } from '@mui/material';
import { format, startOfMonth, endOfMonth } from 'date-fns';
import { sortBy } from '../../../utils/sortBy';
import { Entry, usePostsChartData } from '../../../api/hooks/usePostsChartData';

interface ChartData {
  date: string;
  posts: number;
  reactions: number;
  comments: number;
}

export const PostsChart: React.FC = () => {
  const {
    moonstar: { charts },
  } = useTheme();

  const now = new Date();
  const startDate = startOfMonth(now);
  const endDate = endOfMonth(now);

  const { data: chartData, isPending } = usePostsChartData({
    createdAfter: startDate.toISOString(),
    createdBefore: endDate.toISOString(),
  });

  const aggregatedData: { [key: string]: ChartData } = {};

  const aggregateEntries = (
    entries?: Entry[],
    key: 'posts' | 'reactions' | 'comments' = 'posts'
  ) => {
    entries?.forEach((entry) => {
      const date = format(new Date(entry.createdOn), 'yyyy-MM-dd');
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (!aggregatedData[date]) {
        aggregatedData[date] = { date, posts: 0, reactions: 0, comments: 0 };
      }
      aggregatedData[date][key] += 1;
    });
  };

  if (chartData) {
    aggregateEntries(chartData.Posts, 'posts');
    aggregateEntries(chartData.Reactions, 'reactions');
    aggregateEntries(chartData.Comments, 'comments');
  }

  const formattedData = Object.values(aggregatedData);
  const sortedData = sortBy(formattedData, (x) => x.date);

  if (isPending) {
    return <p>Loading...</p>;
  }

  return (
    <Card elevation={1}>
      <CardHeader title="Feed statistics" />
      <CardContent>
        <ResponsiveContainer width="100%" height={400}>
          <BarChart data={sortedData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="date"
              tickFormatter={(date) => format(new Date(date), 'MMM dd')}
            />
            <YAxis />
            <Tooltip
              labelFormatter={(date) => format(new Date(date), 'MMMM dd, yyyy')}
            />
            <Legend />
            <Bar dataKey="posts" fill={charts.palette.color1} />
            <Bar dataKey="reactions" fill={charts.palette.color2} />
            <Bar dataKey="comments" fill={charts.palette.color3} />
          </BarChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
};
