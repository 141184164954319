import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { AdminApp } from './AdminApp';
import { MoonstarHelmet } from './components/admin/Moonstar/MoonstarHelmet';

export const queryClient = new QueryClient();
const router = createBrowserRouter([{ path: '*', element: <AdminApp /> }]);

export default function App() {
  return (
    <>
      <MoonstarHelmet>
        <title>Moonstar Admin Portal</title>
      </MoonstarHelmet>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
      </QueryClientProvider>
    </>
  );
}
