import { ImageField, ImageInput, required, TextInput } from 'react-admin';
import { MoonstarCreate } from '../Moonstar/MoonstarCreate';
import { MoonstarCreateForm } from '../Moonstar/MoonstarCreateForm';
import { SimpleStack } from '../Moonstar/SimpleStack';
import { Alert, AlertTitle } from '@mui/material';
import { maxFileSize } from '../../../utils/validation';

const imageValidation = maxFileSize('5MB');

export const BadgesCreate = () => {
  return (
    <div style={{ marginTop: 12 }}>
      <Alert severity="warning">
        <AlertTitle>We do not currently support badge deletion</AlertTitle>
        Once a badge is created, you will not be able to delete it directly. You
        can, however, edit the badge name and image later if needed. For badge
        removal, you'll need to contact Moonstar support
      </Alert>

      <MoonstarCreate redirect="list">
        <MoonstarCreateForm>
          <SimpleStack>
            <TextInput source="name" validate={required()} />
            <ImageInput
              source="image"
              accept={{ 'image/*': ['.jpg', '.jpeg', '.png', '.gif'] }}
              sx={{ alignItems: 'start' }}
              label="Badge image"
              validate={imageValidation}
            >
              <ImageField source="src" title="title" />
            </ImageInput>
          </SimpleStack>
        </MoonstarCreateForm>
      </MoonstarCreate>
    </div>
  );
};
