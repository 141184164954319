import * as React from 'react';
import { Layout, LayoutProps } from 'react-admin';
import { MoonstarAppBar } from './AppBar';
import { SideMenuContent } from './SideMenuContent';

export const MoonstarLayout: React.FC<LayoutProps> = ({
  children,
  ...props
}) => (
  <Layout {...props} appBar={MoonstarAppBar} menu={SideMenuContent}>
    {children}
  </Layout>
);
