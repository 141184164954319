import { useCallback, useMemo, useRef } from 'react';
import { fetchUtils, Options } from 'react-admin';
import { HttpClient, HttpClientFetch } from '../api/httpClient';
import { useAuth } from '@clerk/clerk-react';
import { getApiUrl, getFileHostUrl } from '../api/getApiUrl';
import { useTenantId } from '../components/OrganizationGuard';

export const useHttpClient = (): HttpClient => {
  const tenantId = useTenantId();
  const { getToken } = useAuth();
  const lastRequestDate = useRef<Date | null>(null);

  const sendRequest = useCallback<HttpClientFetch>(
    async (url, options) => {
      let skipCache = false;
      if (
        lastRequestDate.current &&
        lastRequestDate.current.getTime() < Date.now() - 1_000 * 60
      ) {
        // Last API request was > 60 seconds ago, so assume that the JWT is expired + get a new one
        skipCache = true;
      }
      lastRequestDate.current = new Date();

      const token = await getToken({
        skipCache,
      });

      const user = {
        authenticated: !!token,
        token: `Bearer ${token}`,
      };

      return await fetchUtils.fetchJson(url, {
        ...options,
        user,
      });
    },
    [getToken]
  );

  const api = useCallback<HttpClientFetch>(
    async (path: string, options: Options = {}) => {
      if (!path.startsWith('/')) {
        throw new Error('Path must start with /');
      }

      const apiUrl = getApiUrl(tenantId);

      return sendRequest(`${apiUrl}${path}`, options);
    },
    [sendRequest, tenantId]
  );

  const fileHost = useCallback<HttpClientFetch>(
    async (path: string, options: Options = {}) => {
      if (!path.startsWith('/')) {
        throw new Error('Path must start with /');
      }

      const apiUrl = getFileHostUrl(tenantId);

      return sendRequest(`${apiUrl}${path}`, options);
    },
    [sendRequest, tenantId]
  );

  return useMemo(
    () => ({
      api,
      fileHost,
    }),
    [api, fileHost]
  );
};
