import { Typography } from '@mui/material';
import { FC, memo, useMemo } from 'react';
import { BooleanInput, useInput } from 'react-admin';
import { MoonstarModal } from '../../MoonstarModal';
import {
  SelectListDto,
  SelectListResource,
  useFetchSelectList,
} from '../../../api/hooks/useFetchSelectList';
import { MuiAutocompleteArray } from '../../MuiAutocomplete';

export type PostSettingsData = {
  departments: string[];
  jobTitles: string[];
  regions: string[];
  units: string[];
  visibleForEveryone: boolean;

  isImportant: boolean;
  allowComments: boolean;
  allowReactions: boolean;

  publishingDate: string;
};

type PostSettingsModalProps = {
  showSettingsModal: boolean;
  onCloseSettingsModal: () => void;
};

export const PostSettingsModal = memo(function PostSettingsModal({
  showSettingsModal,
  onCloseSettingsModal,
}: PostSettingsModalProps) {
  return (
    <MoonstarModal
      title="Post settings"
      open={showSettingsModal}
      onClose={onCloseSettingsModal}
      content={
        <>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <BooleanInput source="isImportant" />
            <BooleanInput source="allowComments" defaultValue={true} />
            <BooleanInput source="allowReactions" defaultValue={true} />
          </div>
          <Typography variant="h6">Visibilities</Typography>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <SelectListInput source="departments" selectList="Departments" />
            <SelectListInput source="jobTitles" selectList="JobTitles" />
            <SelectListInput source="regions" selectList="Regions" />
            <SelectListInput source="units" selectList="Units" />
          </div>
        </>
      }
      actions={[{ text: 'Close', action: onCloseSettingsModal }]}
    />
  );
});

const SelectListInput: FC<{
  source: string;
  selectList: SelectListResource;
}> = (props) => {
  const resources = useFetchSelectList(props.selectList);
  const { field, isRequired } = useInput({ source: props.source });

  const values = useMemo(() => {
    if (!resources.data || !Array.isArray(field.value)) return [];
    return field.value
      .map((id: number) => resources.data.find((r) => r.id === id))
      .filter((r): r is NonNullable<SelectListDto> => !!r);
  }, [resources, field.value]);

  return (
    <MuiAutocompleteArray
      getOptionLabel={(option) => option.name}
      getOptionKey={(option) => option.id}
      label={props.selectList}
      onChange={(_, value) => field.onChange(value.map((v) => v.id))}
      required={isRequired}
      options={resources.data ?? []}
      isError={resources.isError}
      isPending={resources.isPending}
      value={values}
    />
  );
};
