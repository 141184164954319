import { ReactAdminEditedFile } from '../api/transformHooks/fileUpload';

export const getItemsByTypeFromAttachments = (
  attachments: ReactAdminEditedFile[] | undefined
) => {
  if (!attachments) {
    return {
      images: [],
      videos: [],
      pdfs: [],
    };
  }

  const imageFiles = attachments.filter((imageFile: ReactAdminEditedFile) =>
    imageFile.rawFile.type.includes('image')
  );

  const videoFiles = attachments.filter((videoFile: ReactAdminEditedFile) =>
    videoFile.rawFile.type.includes('video')
  );

  const pdfFiles = attachments.filter((pdfFile: ReactAdminEditedFile) =>
    pdfFile.rawFile.type.includes('pdf')
  );

  return {
    images: imageFiles,
    videos: videoFiles,
    pdfs: pdfFiles,
  };
};
