import { GroupChannelProps } from '@sendbird/uikit-react/GroupChannel';
import GroupChannelUI from '@sendbird/uikit-react/GroupChannel/components/GroupChannelUI';
import { useGroupChannelContext } from '@sendbird/uikit-react/GroupChannel/context';
import MessageContent from '@sendbird/uikit-react/ui/MessageContent';
import { useCallback, useEffect, useState } from 'react';
import { GroupCustomHeader } from './GroupCustomHeader';
import { MessageCustomMenu } from './MessageCustomMenu/MessageCustomMenu';
import { useBlocker } from 'react-router-dom';
import { ConfirmDiscardChannelModal } from '../ConfirmDiscardChannelModal';

export const GroupCustomChannelUI = ({
  onSearchClick,
  onChatHeaderActionClick,
}: Pick<GroupChannelProps, 'onSearchClick' | 'onChatHeaderActionClick'>) => {
  const context = useGroupChannelContext();
  const [open, setOpen] = useState(false);

  // Handle file drop or paste
  const handleEvent = useCallback(
    (event: DragEvent | ClipboardEvent) => {
      event.preventDefault();
      let files: FileList | null = null;

      if (event.type === 'drop' && event instanceof DragEvent) {
        files = event.dataTransfer?.files || null;
      } else if (event.type === 'paste' && event instanceof ClipboardEvent) {
        files = event.clipboardData?.files || null;
      }

      if (files && files.length > 0) {
        const file = files[0];
        void context.sendFileMessage({ file });
      }
    },
    [context]
  );

  // Add/remove event listeners for drag-and-drop and paste
  useEffect(() => {
    const preventDefault = (event: Event) => event.preventDefault();

    window.addEventListener('dragover', preventDefault);
    window.addEventListener('drop', handleEvent);
    window.addEventListener('paste', handleEvent);

    return () => {
      window.removeEventListener('dragover', preventDefault);
      window.removeEventListener('drop', handleEvent);
      window.removeEventListener('paste', handleEvent);
    };
  }, [handleEvent]);

  // Block navigation with router if there are no messages in the channel
  const blocker = useBlocker(() => {
    let shouldBlock = false;
    if (context.messages.length === 0) {
      setOpen(true);
      shouldBlock = true;
    }

    return shouldBlock;
  });

  const handleDiscard = useCallback(() => {
    setOpen(false);
    blocker.proceed?.();
  }, [blocker]);

  const handleCancel = useCallback(() => {
    setOpen(false);
    blocker.reset?.();
  }, [blocker]);

  return (
    <>
      <GroupChannelUI
        renderChannelHeader={(props) => (
          <GroupCustomHeader
            {...props}
            onSearchClick={onSearchClick}
            onChatHeaderActionClick={onChatHeaderActionClick}
          />
        )}
        renderMessageContent={(props) => (
          <MessageContent
            {...props}
            renderMessageMenu={(menuProps) => (
              <MessageCustomMenu {...menuProps} />
            )}
          />
        )}
      />

      <ConfirmDiscardChannelModal
        open={open}
        onClose={handleCancel}
        onDiscard={handleDiscard}
      />
    </>
  );
};
