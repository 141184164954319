import MenuListByRole from '@sendbird/uikit-react/ChannelSettings/components/ChannelSettingMenuList';
import { useChannelSettingsContext } from '@sendbird/uikit-react/ChannelSettings/context';
import useMenuItems from '@sendbird/uikit-react/ChannelSettings/hooks/useMenuList';
import { MemberList } from './MembersList';

export const CustomModerationPanel = () => {
  const menuItems = useMenuItems();
  const { renderUserListItem } = useChannelSettingsContext();

  const customMenuItems: typeof menuItems = {
    operator: {
      ...menuItems.operator,
      allUsers: {
        ...menuItems.operator.allUsers,
        accordionComponent: () => (
          <MemberList canInviteUsers renderUserListItem={renderUserListItem} />
        ),
      },
    },
    nonOperator: {
      ...menuItems.nonOperator,
      allUsers: {
        ...menuItems.operator.allUsers,
        accordionComponent: () => (
          <MemberList
            canInviteUsers={false}
            renderUserListItem={renderUserListItem}
          />
        ),
      },
    },
  };

  return <MenuListByRole menuItems={customMenuItems} />;
};
