import {
  Button,
  ChipField,
  Datagrid,
  ImageField,
  Link,
  ReferenceArrayField,
  SingleFieldList,
  TextField,
} from 'react-admin';
import { useParams } from 'react-router-dom';
import { MoonstarList } from '../../Moonstar/MoonstarList';
import { BasicUserFilters } from '../../User/UserFilters';
import { profilePictureStyles } from '../../User/profilePictureStyles';
import { MoonstarListActions } from '../../Moonstar/MoonstarListActions';
import { BulkRemoveCohortMembers } from './BulkRemoveCohortMembers';
import { FC } from 'react';
import { BulkAddCohortMembers } from './BulkAddCohortMembers';
import { Box, Typography } from '@mui/material';
import {
  GroupAddOutlined as GroupAddOutlinedIcon,
  GroupOutlined as GroupOutlinedIcon,
  Inbox as InboxIcon,
} from '@mui/icons-material';

type DisplayMode = 'members' | 'non-members';

export interface CohortMembershipListProps {
  displaying: DisplayMode;
}

export const CohortMembershipList: FC<CohortMembershipListProps> = (props) => {
  const { cohortId } = useParams<{ cohortId: string }>();
  const numericCohortId = Number(cohortId);
  const invitingUsers = props.displaying === 'non-members';
  const filterKey = invitingUsers ? 'excludeCohortIds' : 'cohortIds';

  return (
    <MoonstarList
      key={props.displaying}
      empty={
        props.displaying === 'members' ? (
          <CohortMembersListEmpty cohortId={numericCohortId} />
        ) : (
          <CohortAddMembersEmpty cohortId={numericCohortId} />
        )
      }
      filters={BasicUserFilters}
      filter={{
        [filterKey]: [numericCohortId],
        userIsDisabled: false,
      }}
      actions={<MoonstarListActions hideCreate />}
      resource="users"
    >
      <Datagrid
        bulkActionButtons={
          invitingUsers ? (
            <BulkAddCohortMembers cohortId={numericCohortId} />
          ) : (
            <BulkRemoveCohortMembers cohortId={numericCohortId} />
          )
        }
        rowClick={false}
      >
        <ImageField
          source="pictureUrl"
          sx={profilePictureStyles}
          sortable={false}
        />
        <TextField source="internalId" label="Internal ID" />
        <TextField source="firstName" />
        <TextField source="lastName" />
        <TextField source="phoneNumber" />
        <TextField source="email" />
        <TextField source="jobTitleName" />
        <TextField source="departmentName" />
        <TextField source="regionName" />
        <ReferenceArrayField
          source="unitIds"
          reference="units"
          sortable={false}
        >
          <SingleFieldList linkType={false}>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
      </Datagrid>
    </MoonstarList>
  );
};

const CohortMembersListEmpty: FC<{ cohortId: number }> = (props) => (
  <Box textAlign="center" m={1}>
    <Box sx={{ opacity: 0.5 }}>
      <InboxIcon sx={{ width: '9em', height: '9em' }} />
      <Typography variant="h4" paragraph>
        No members yet
      </Typography>
    </Box>
    <Button
      component={Link}
      to={`/cohorts/${props.cohortId}/members/add`}
      startIcon={<GroupAddOutlinedIcon />}
      label="Add members"
    />
  </Box>
);

const CohortAddMembersEmpty: FC<{ cohortId: number }> = (props) => (
  <Box textAlign="center" m={1}>
    <Box sx={{ opacity: 0.5 }}>
      <InboxIcon sx={{ width: '9em', height: '9em' }} />
      <Typography variant="h4" paragraph>
        No non-members
      </Typography>
    </Box>
    <Button
      component={Link}
      to={`/cohorts/${props.cohortId}/members`}
      startIcon={<GroupOutlinedIcon />}
      label="View members"
    />
  </Box>
);
