import { useDataProvider } from 'react-admin';
import { AdminDataProvider } from '../adminDataProvider';
import { HttpClient } from '../httpClient';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

export const fetchAllTopics = (httpClient: HttpClient) => async () => {
  const response = await httpClient.api('/topics');

  return response.json as TopicDto[];
};

export interface TopicDto {
  id: number;
  logo: string | undefined;
  displayName: string | undefined;
  isDefault: boolean;
  formType: FormTypeDto;
}

export interface FormTypeDto {
  id: number;
  typeName?: string;
}

export const useFetchAllTopics = (
  options?: UseQueryOptions<TopicDto[], Error, TopicDto[]>
) => {
  const dataProvider = useDataProvider<AdminDataProvider>();

  return useQuery<TopicDto[]>({
    queryKey: ['fetchAllTopics'],
    queryFn: () => dataProvider.fetchAllTopics(),
    staleTime: 1000 * 60,
    ...options,
  });
};
