import Leaderboard from '@mui/icons-material/Leaderboard';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import MarkChatUnreadOutlinedIcon from '@mui/icons-material/MarkChatUnreadOutlined';
import { Menu } from 'react-admin';
import { useDashboardVisibility } from '../hooks/useDashboardVisibility';
import { CustomResourcesValue } from '../components/admin/AdminResources';
import { useGetTotalUnreadMessageCount } from '../api/hooks/sendbird/useGetTotalUnreadMessageCount';
import { MOONSTAR_THEME } from '../theme';

export const SideMenuContent = () => {
  const { showDashboard } = useDashboardVisibility();
  const { data: totalUnreadMessageCount } = useGetTotalUnreadMessageCount();

  const hasUnreadMessages = !!totalUnreadMessageCount;

  return (
    <Menu>
      {showDashboard && (
        <Menu.Item to="/" primaryText="Dashboard" leftIcon={<Leaderboard />} />
      )}
      <Menu.Item
        to={`/${CustomResourcesValue.CHAT}`}
        primaryText={
          hasUnreadMessages ? `(${totalUnreadMessageCount}) Chat` : 'Chat'
        }
        leftIcon={
          hasUnreadMessages ? (
            <MarkChatUnreadOutlinedIcon
              style={{ color: MOONSTAR_THEME.palette.secondary.main }}
            />
          ) : (
            <ChatBubbleOutlineOutlinedIcon />
          )
        }
      />
      <Menu.ResourceItems />
    </Menu>
  );
};
