import { zodResolver } from '@hookform/resolvers/zod';
import { styled } from '@mui/material';
import { memo, ReactElement, useEffect, useState } from 'react';
import { FileField, FileInput, TextInput } from 'react-admin';
import { useFormContext, useWatch } from 'react-hook-form';
import { getPostDefaultValues } from '../../../schemas/Post/PostDefaultValues';
import { PostSchema } from '../../../schemas/Post/PostSchema';
import { SettingsIconButton } from '../../custom/SettingsIconButton';
import { MoonstarCreateForm } from '../Moonstar/MoonstarCreateForm';
import { SimpleStack } from '../Moonstar/SimpleStack';
import { usePostCreateContext } from './PostCreateProvider';
import { PostSettingsData, PostSettingsModal } from './PostSettingsModal';
import { TopicSelector } from './TopicSelector';

export type BasicFormData = {
  topicId: number;
  content: string;
  attachments: { src: string }[];
} & PostSettingsData;

type BasicFormProps = {
  formToolbar?: ReactElement;
};

const FormContent = () => {
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const { selectedTopic, handleTopicChange, setFormData } =
    usePostCreateContext();

  const { control } = useFormContext();
  const formState = useWatch({
    control,
    name: ['topicId', 'content', 'attachments'],
  });

  useEffect(() => {
    setFormData(formState);
  }, [formState, setFormData]);

  const handleCloseSettingsModal = () => {
    setShowSettingsModal(false);
  };

  return (
    <SimpleStack style={{ flex: 1, width: '100%' }}>
      <TopicAndSettingsContainer>
        <TopicSelector
          selectedTopic={selectedTopic}
          handleTopicChange={handleTopicChange}
        />
        <SettingsIconButton onClick={() => setShowSettingsModal(true)} />
      </TopicAndSettingsContainer>

      <TextInput
        multiline
        minRows={7}
        placeholder="Share your thoughts and use @ to mention a colleague"
        source="content"
        sx={{
          width: 'auto',
        }}
        isRequired
      />

      <FileInput
        source="attachments"
        multiple
        accept={{
          'image/*': ['.jpg', '.jpeg', '.png', '.gif'],
          'video/*': ['.mp4', '.avi'],
          'application/pdf': ['.pdf'],
        }}
        sx={{ alignItems: 'start', ':hover': { cursor: 'pointer' } }}
      >
        <FileField source="src" title="title" />
      </FileInput>

      <PostSettingsModal
        showSettingsModal={showSettingsModal}
        onCloseSettingsModal={handleCloseSettingsModal}
      />
    </SimpleStack>
  );
};

export const BasicForm = memo(function BasicForm({
  formToolbar,
}: BasicFormProps) {
  const { selectedTopic } = usePostCreateContext();

  return (
    <MoonstarCreateForm
      toolbar={formToolbar}
      defaultValues={() => getPostDefaultValues(selectedTopic)}
      resolver={zodResolver(PostSchema)}
    >
      <FormContent />
    </MoonstarCreateForm>
  );
});

export const TopicAndSettingsContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
}));
