export const truncatePost = (
  record: { title?: string; content?: string },
  maxLength: number = 70
) => {
  if (!record.title && !record.content) return '';

  let text = record.title ?? '';
  if (record.content)
    text = text ? `${text}: ${record.content}` : record.content;
  if (!text) return '';

  let result = text.substring(0, maxLength);
  if (result.length < text.length) result = result + '...';
  return result;
};
