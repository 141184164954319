import { Alert } from '@mui/material';
import { GroupChannel } from '@sendbird/chat/groupChannel';
import { OpenChannel } from '@sendbird/chat/openChannel';
import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';
import { useNotify, useTranslate } from 'react-admin';
import { SendableMessage } from '../../../components/admin/Chat/ForwardMessageProvider';
import { MAX_PINNED_MESSAGES } from '../../../components/admin/Chat/GroupCustomChannel/MessageCustomMenu/PinUnpinMenuItem';

type PinUnpinMessageParams = {
  message: SendableMessage;
  channel: GroupChannel | OpenChannel;
  isPinned: boolean | undefined;
};

const pinOrUnpinMessage = async ({
  message,
  channel,
  isPinned,
}: PinUnpinMessageParams) => {
  if (isPinned) {
    await channel.unpinMessage(message.messageId);
  } else {
    await channel.pinMessage(message.messageId);
  }
};

export const usePinUnpinMessage = (
  options?: UseMutationOptions<void, Error, PinUnpinMessageParams>
) => {
  const notify = useNotify();
  const translate = useTranslate();
  const queryClient = useQueryClient();

  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  return useMutation<void, Error, PinUnpinMessageParams>({
    mutationKey: ['pinOrUnpinMessage'],
    mutationFn: pinOrUnpinMessage,
    ...options,
    onError: () => {
      notify(
        <Alert severity="error">{translate('chat.somethingWentWrong')}</Alert>,
        {
          autoHideDuration: 5000,
        }
      );
    },
    onSuccess: async (_data, variable) => {
      if (variable.isPinned) {
        notify(<Alert>{translate('chat.unpinMessage')}</Alert>);
      } else {
        notify(
          <Alert>
            {translate('chat.pinMessage', {
              maxPinnedMessages: MAX_PINNED_MESSAGES,
            })}
          </Alert>
        );
      }

      await queryClient.invalidateQueries({
        queryKey: ['pinned-messages', variable.channel.url],
      });
    },
  });
};
