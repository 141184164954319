import { User } from '../components/admin/User/types';
import { GroupChannel } from '@sendbird/chat/groupChannel';

/**
 * Returns channel name if it was set by the user, if not, returns the `string` with the nicknames of the members of the channel.
 */
export const getChatChannelName = (
  channel: GroupChannel,
  currentUser: User
): string => {
  if (channel.name.length > 0) {
    return channel.name;
  }

  const membersExcludingCurrentUser = channel.members.filter(
    (m) => m.userId !== currentUser.id.toString()
  );

  return membersExcludingCurrentUser.map((m) => m.nickname).join(', ');
};
