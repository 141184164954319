import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useAuthState, useDataProvider } from 'react-admin';
import { AdminDataProvider } from '../api/adminDataProvider';
import { useQuery } from '@tanstack/react-query';

export const useGetUserRole = (options?: { enabled?: boolean }) => {
  const provider = useDataProvider<AdminDataProvider>();

  return useQuery({
    queryKey: ['fetch-user-role'],
    queryFn: provider.fetchUserRole,
    staleTime: 1000 * 60,
    ...(options ?? {}),
  });
};

interface UserRoleContextProps {
  userRole: string;
  setUserRole: Dispatch<SetStateAction<string>>;
}

const UserRoleContext = createContext<UserRoleContextProps | undefined>(
  undefined
);

export const UserRoleProvider = ({ children }: { children: ReactNode }) => {
  const { isPending, authenticated } = useAuthState();

  const options = {
    enabled: isPending ? false : authenticated,
  };
  const { data: initialUserRole } = useGetUserRole(options);

  const [userRole, setUserRole] = useState('User');

  useEffect(() => {
    if (initialUserRole) {
      setUserRole(initialUserRole);
    }
  }, [initialUserRole]);

  const value = useMemo(() => ({ userRole, setUserRole }), [userRole]);

  return (
    <UserRoleContext.Provider value={value}>
      {children}
    </UserRoleContext.Provider>
  );
};

export const useUserRoleContext = () => {
  const context = useContext(UserRoleContext);
  if (!context) {
    throw new Error(
      'useUserRoleContext must be used within a UserRoleProvider'
    );
  }
  return context;
};
