import { zodResolver } from '@hookform/resolvers/zod';
import {
  memo,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  AutocompleteInput,
  FileField,
  FileInput,
  ReferenceInput,
  TextInput,
} from 'react-admin';
import { useFormContext, useWatch } from 'react-hook-form';
import { getRecognitionDefaultValues } from '../../../schemas/Recognition/RecognitionDefaultValues';
import { RecognitionSchema } from '../../../schemas/Recognition/RecognitionSchema';
import { MoonstarCreateForm } from '../Moonstar/MoonstarCreateForm';
import { SimpleStack } from '../Moonstar/SimpleStack';
import { User } from '../User/types';
import { TopicAndSettingsContainer } from './BasicForm';
import { usePostCreateContext } from './PostCreateProvider';
import { PostSettingsData, PostSettingsModal } from './PostSettingsModal';
import { useGetCurrentUser } from '../../../hooks/useGetCurrentUser';
import { SettingsIconButton } from '../../custom/SettingsIconButton';
import { UserOptionRenderer } from './UserOptionRenderer';
import { TopicSelector } from './TopicSelector';

export type RecognitionFormData = {
  topicId: number;
  content: string;
  recognizedUserId: number;
  badgeId: number;
  attachments: { src: string }[];
} & PostSettingsData;

type RecognitionFormProps = {
  formToolbar?: ReactElement;
};

const FormContent = () => {
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [recognizedUser, setRecognizedUser] = useState<User>();
  const [badge, setBadge] = useState<{
    id: number;
    name: string;
    imageUrl: string;
  }>();

  const { control, setValue } = useFormContext();
  const formState = useWatch({
    control,
    name: ['topicId', 'recognizedUserId', 'badgeId', 'content', 'attachments'],
  });

  const { data: currentUserData } = useGetCurrentUser();

  const { selectedTopic, handleTopicChange, setFormData } =
    usePostCreateContext();

  const handleCloseSettingsModal = () => {
    setShowSettingsModal(false);
  };

  useEffect(() => {
    setFormData(formState);
  }, [formState, setFormData]);

  // TODO: Remove this once recognition no longer requires a title for comms app. This is a temporary fix for backwards compatibility.
  const updateTitle = useCallback(
    (params: {
      badge?: typeof badge;
      recognizedUser?: typeof recognizedUser;
    }) => {
      if (params.badge) setBadge(params.badge);
      if (params.recognizedUser) setRecognizedUser(params.recognizedUser);

      const user = params.recognizedUser || recognizedUser;
      const value = params.badge || badge;

      const title = `${currentUserData?.displayName} awarded ${user?.firstName} ${user?.lastName} with badge ${value?.name}`;

      setValue('title', title);
    },
    [badge, recognizedUser, currentUserData, setValue]
  );

  // Needed for displaying avatars in recognized user dropdown: https://marmelab.com/react-admin/AutocompleteInput.html#using-a-custom-element-for-options
  const optionText = useMemo(() => <UserOptionRenderer />, []);

  return (
    <SimpleStack style={{ flex: 1, width: '100%' }}>
      <TopicAndSettingsContainer>
        <TopicSelector
          selectedTopic={selectedTopic}
          handleTopicChange={handleTopicChange}
        />
        <SettingsIconButton onClick={() => setShowSettingsModal(true)} />
      </TopicAndSettingsContainer>

      {/* Recognized user input */}
      <ReferenceInput
        source="recognizedUserId"
        sort={{ field: 'firstName', order: 'ASC' }}
        reference="users"
        placeholder="Tag a colleague"
      >
        <AutocompleteInput
          isRequired
          style={{ maxWidth: '250px' }}
          TextFieldProps={{
            placeholder: 'Tag a colleague',
          }}
          optionText={optionText}
          inputText={(choice) => {
            const user = choice as User;
            return `${user.firstName} ${user.lastName}`;
          }}
          onChange={(_v, record) =>
            updateTitle({ recognizedUser: record as User })
          }
        />
      </ReferenceInput>

      {/* Badge input */}
      <ReferenceInput
        source="badgeId"
        sort={{ field: 'id', order: 'ASC' }}
        reference="badges"
      >
        <AutocompleteInput
          isRequired
          style={{ maxWidth: '250px' }}
          optionText="name"
          TextFieldProps={{
            placeholder: 'Select a value',
          }}
          onChange={(_v, record) =>
            updateTitle({
              badge: record as { id: number; name: string; imageUrl: string },
            })
          }
        />
      </ReferenceInput>

      {/* Content value input */}
      <TextInput
        multiline
        minRows={7}
        source="content"
        placeholder="Share your thoughts and use @ to mention a colleague"
        sx={{
          width: 'auto',
        }}
        isRequired
      />

      <FileInput
        source="attachments"
        multiple
        accept={{
          'image/*': ['.jpg', '.jpeg', '.png', '.gif'],
          'video/*': ['.mp4'],
          'application/pdf': ['.pdf'],
        }}
        sx={{ alignItems: 'start', ':hover': { cursor: 'pointer' } }}
      >
        <FileField source="src" title="title" />
      </FileInput>

      <PostSettingsModal
        showSettingsModal={showSettingsModal}
        onCloseSettingsModal={handleCloseSettingsModal}
      />
    </SimpleStack>
  );
};

export const RecognitionForm = memo(function RecognitionForm({
  formToolbar,
}: RecognitionFormProps) {
  const { selectedTopic } = usePostCreateContext();

  return (
    <MoonstarCreateForm
      toolbar={formToolbar}
      defaultValues={() => getRecognitionDefaultValues(selectedTopic)}
      resolver={zodResolver(RecognitionSchema)}
    >
      <FormContent />
    </MoonstarCreateForm>
  );
});
