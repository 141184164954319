import { UserLite } from '../../components/admin/User/types';
import { HttpClient } from '../httpClient';

export const getUserByInternalId =
  (httpClient: HttpClient) => async (internalId: string) => {
    const response = await httpClient.api(
      `/admin/users/getByInternalId/${internalId}`,
      {
        method: 'GET',
      }
    );

    return response.body ? (response.json as UserLite) : undefined;
  };
