import { zodResolver } from '@hookform/resolvers/zod';
import { useMediaQuery } from '@mui/material';
import { memo, ReactElement, useEffect, useState } from 'react';
import { ArrayInput, FileField, FileInput, TextInput } from 'react-admin';
import { useFormContext, useWatch } from 'react-hook-form';
import { getPollDefaultValues } from '../../../schemas/Poll/PollDefaultValues';
import { MIN_POLL_OPTIONS, PollSchema } from '../../../schemas/Poll/PollSchema';
import { MoonstarCreateForm } from '../Moonstar/MoonstarCreateForm';
import { SimpleStack } from '../Moonstar/SimpleStack';
import { TopicAndSettingsContainer } from './BasicForm';
import { usePostCreateContext } from './PostCreateProvider';
import { PostSettingsModal } from './PostSettingsModal';
import { SettingsIconButton } from '../../custom/SettingsIconButton';
import { PollOptionFormIterator } from './PollOptionFormIterator';
import { TopicSelector } from './TopicSelector';

export const MAX_POLL_OPTIONS = 10;

type PollFormProps = {
  formToolbar?: ReactElement;
};

const FormContent = () => {
  const [showSettingsModal, setShowSettingsModal] = useState(false);

  const { control } = useFormContext();
  const formState = useWatch({
    control,
    name: ['topicId', 'title', 'options', 'pdf'],
  });

  const isMobile = useMediaQuery('(max-width:425px)');
  const { selectedTopic, handleTopicChange, setFormData } =
    usePostCreateContext();

  const noOfOptions = formState[2].length ?? MIN_POLL_OPTIONS;

  useEffect(() => {
    setFormData(formState);
  }, [formState, setFormData]);

  const handleCloseSettingsModal = () => {
    setShowSettingsModal(false);
  };

  return (
    <SimpleStack style={{ flex: 1, width: '100%' }}>
      <TopicAndSettingsContainer>
        <TopicSelector
          selectedTopic={selectedTopic}
          handleTopicChange={handleTopicChange}
        />
        <SettingsIconButton onClick={() => setShowSettingsModal(true)} />
      </TopicAndSettingsContainer>

      <TextInput
        source="title"
        placeholder="Share your thoughts and use @ to mention a colleague"
        sx={{
          width: 'auto',
        }}
        isRequired
      />

      <ArrayInput
        source="options"
        sx={{
          width: 'auto',
          minWidth: isMobile ? '300px' : '450px',
          maxWidth: '450px',
        }}
      >
        <PollOptionFormIterator
          inline
          disableClear={true}
          disableRemove={noOfOptions <= MIN_POLL_OPTIONS}
          disableAdd={noOfOptions >= MAX_POLL_OPTIONS}
        >
          <TextInput
            source="text"
            helperText={false}
            label="Option"
            isRequired
          />
        </PollOptionFormIterator>
      </ArrayInput>

      <FileInput
        source="pdf"
        accept={{
          'application/pdf': ['.pdf'],
        }}
        sx={{ alignItems: 'start', ':hover': { cursor: 'pointer' } }}
      >
        <FileField source="src" title="title" />
      </FileInput>

      <PostSettingsModal
        showSettingsModal={showSettingsModal}
        onCloseSettingsModal={handleCloseSettingsModal}
      />
    </SimpleStack>
  );
};

export const PollForm = memo(function PollForm({ formToolbar }: PollFormProps) {
  const { selectedTopic } = usePostCreateContext();

  return (
    <MoonstarCreateForm
      toolbar={formToolbar}
      defaultValues={() => getPollDefaultValues(selectedTopic)}
      resolver={zodResolver(PollSchema)}
    >
      <FormContent />
    </MoonstarCreateForm>
  );
});
