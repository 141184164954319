import { FC, ReactNode } from 'react';
import {
  Edit,
  EditProps,
  useGetResourceLabel,
  useResourceContext,
  useTranslate,
} from 'react-admin';
import { MoonstarHelmet } from './MoonstarHelmet';

export const MoonstarEdit: FC<EditProps & { children: ReactNode }> = (
  props
) => {
  const resourceContext = useResourceContext();
  const getLabel = useGetResourceLabel();
  const translate = useTranslate();

  const label = getLabel(resourceContext as string, 1);
  const edit = translate('ra.action.edit');
  const appName = translate('moonstar.appName');

  return (
    <Edit
      title={`${appName} / ${label} / ${edit}`}
      mutationMode="pessimistic"
      {...props}
    >
      <>
        <MoonstarHelmet>
          <title>
            {edit} | {label} | {appName}
          </title>
        </MoonstarHelmet>
        {props.children}
      </>
    </Edit>
  );
};
