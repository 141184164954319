import { useDataProvider } from 'react-admin';
import { AdminDataProvider } from '../adminDataProvider';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { HttpClient } from '../httpClient';

export const getBadgesAwardsChartData =
  (httpClient: HttpClient) => async (data: BadgeAwardsChartParams) => {
    const badgeIdFilter = data.badgeIdFilter?.map((b) => parseInt(b, 10));

    const response = await httpClient.api('/admin/badges/chart', {
      method: 'POST',
      body: JSON.stringify({
        ...data,
        badgeIdFilter,
      }),
    });

    return response.json as Entry[];
  };

export type BadgeAwardsChartParams = {
  createdAfter?: string;
  createdBefore?: string;
  badgeIdFilter?: string[];
};

export type Entry = {
  date: string;
  count: number;
};

export const useBadgesAwardsChartData = (
  params: BadgeAwardsChartParams,
  options?: UseQueryOptions<Entry[], Error, Entry[]>
) => {
  const dataProvider = useDataProvider<AdminDataProvider>();

  return useQuery<Entry[]>({
    queryKey: ['getBadgesAwardsChartData', params],
    queryFn: () => dataProvider.getBadgesAwardsChartData(params),
    staleTime: 1000 * 60,
    ...options,
  });
};
