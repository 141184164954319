import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
} from '@mui/material';
import { useGetAllUsers } from '../../../../hooks/useGetAllUsers';
import { CenteredCircularProgress } from '../../../custom/CenteredCircularProgress';
import { UserList } from '../UserList';
import { useCallback, useRef, useState } from 'react';
import { ChannelUserCard } from '../ChannelUserCard';
import { ChannelUserCardCheckbox } from '../ChannelUserCardCheckbox';
import { UserDto } from '../../../../api/customEndpoints/fetchAllUsers';
import { SearchTextInput } from '../SearchTextInput';
import { useCreateChannelContext } from '@sendbird/uikit-react/CreateChannel/context';
import { GroupChannel } from '@sendbird/chat/groupChannel';
import { useTranslate } from 'react-admin';
import { LoadingButton } from '../../../LoadingButton';
import { useGetCurrentUser } from '../../../../hooks/useGetCurrentUser';
import { useCreateChannel } from '../../../../api/hooks/sendbird/useCreateChannel';
import { CHANNEL_TYPE } from './types';

export const CreateGroupsModal = (props: DialogProps) => {
  const translate = useTranslate();
  const containerRef = useRef(null);
  const { data: users, isPending } = useGetAllUsers();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedUsers, setSelectedUsers] = useState<UserDto[]>([]);
  const { data: currentUser, isPending: isPendingCurrentUser } =
    useGetCurrentUser();
  const { type, createChannel, onChannelCreated } = useCreateChannelContext();

  const handleCreateChannelSuccess = useCallback(
    (newChannel?: GroupChannel) => {
      if (!newChannel) {
        return;
      }

      // Clear the user selections and search term on successful group creation
      setSearchTerm('');
      setSelectedUsers([]);
      props.onClose?.({}, 'escapeKeyDown');
      onChannelCreated?.(newChannel);
    },
    [onChannelCreated, props]
  );

  const { mutateAsync, isPending: isCreatingChannel } = useCreateChannel({
    onSuccess: handleCreateChannelSuccess,
  });

  const handleCreateGroup = useCallback(async () => {
    if (!currentUser) {
      return;
    }

    await mutateAsync({
      selectedUsers,
      type,
      currentUser,
      createChannelFn: createChannel,
    });
  }, [selectedUsers, currentUser, type, createChannel, mutateAsync]);

  const handleCancel = () => {
    setSelectedUsers([]);
    props.onClose?.({}, 'backdropClick');
  };

  return (
    <Dialog {...props} scroll="paper" fullWidth>
      {isPending ? (
        <CenteredCircularProgress />
      ) : (
        <>
          <DialogTitle sx={{ paddingX: 2, paddingY: 1 }}>
            {translate('chat.createGroupsModalTitle')}
            <SearchTextInput
              label={
                type.toString() === CHANNEL_TYPE.GROUP.toString()
                  ? translate('chat.searchNewGroup')
                  : translate('chat.searchNewSupergroup')
              }
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
            />
          </DialogTitle>
          <DialogContent sx={{ padding: 0 }} ref={containerRef}>
            {users && (
              <UserList
                searchTerm={searchTerm}
                userIdsFilter={users.map((u) => u.id)}
                containerRef={containerRef}
                renderUser={(u) => (
                  <ChannelUserCard
                    user={u}
                    selectedUsers={selectedUsers}
                    setSelectedUsers={setSelectedUsers}
                    right={
                      <ChannelUserCardCheckbox
                        user={u}
                        selectedUsers={selectedUsers}
                      />
                    }
                  />
                )}
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel} style={{ margin: 8 }}>
              {translate('chat.close')}
            </Button>
            <LoadingButton
              variant="contained"
              onClick={() => void handleCreateGroup()}
              style={{ margin: 8 }}
              loading={isPendingCurrentUser || isCreatingChannel}
              disabled={isCreatingChannel || selectedUsers.length === 0}
            >
              {translate('chat.create')}
            </LoadingButton>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};
