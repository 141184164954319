import InfoIcon from '@mui/icons-material/InfoOutlined';
import PushPinIcon from '@mui/icons-material/PushPinOutlined';
import SearchIcon from '@mui/icons-material/Search';
import { IconButton, Typography } from '@mui/material';
import { GroupChannelProps } from '@sendbird/uikit-react/GroupChannel';
import {
  GroupChannelHeader,
  GroupChannelHeaderProps,
} from '@sendbird/uikit-react/GroupChannel/components/GroupChannelHeader';
import { useState } from 'react';
import { PinnedMessagesPopover } from './PinnedMessagesPopover/PinnedMessagesPopover';
import { useGroupChannelContext } from '@sendbird/uikit-react/GroupChannel/context';
import { getChatChannelName } from '../../../../utils/getChatChannelName';
import { useGetCurrentUser } from '../../../../hooks/useGetCurrentUser';
import { Loading } from 'react-admin';

type GroupCustomHeaderProps = Pick<
  GroupChannelProps,
  'onSearchClick' | 'onChatHeaderActionClick'
> &
  GroupChannelHeaderProps;

export const GroupCustomHeader = (props: GroupCustomHeaderProps) => {
  const { data: currentUser } = useGetCurrentUser();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { currentChannel } = useGroupChannelContext();

  if (!currentChannel || !currentUser) {
    return <Loading />;
  }

  return (
    <GroupChannelHeader
      {...props}
      renderMiddle={() => (
        <Typography
          variant="h6"
          sx={{
            marginLeft: 1,
            width: '45vw',
            justifyContent: 'center',
            alignSelf: 'flex-start',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {getChatChannelName(currentChannel, currentUser)}
        </Typography>
      )}
      renderRight={() => (
        <>
          {!currentChannel.isSuper && (
            <IconButton
              color="primary"
              aria-label="pinned messages"
              size="large"
              onClick={(e) => setAnchorEl(e.currentTarget)}
            >
              <PushPinIcon />
            </IconButton>
          )}

          <IconButton
            color="primary"
            aria-label="search messages"
            size="large"
            onClick={props.onSearchClick}
          >
            <SearchIcon />
          </IconButton>

          <IconButton
            color="primary"
            aria-label="settings"
            size="large"
            onClick={props.onChatHeaderActionClick}
          >
            <InfoIcon />
          </IconButton>

          {!currentChannel.isSuper && (
            <PinnedMessagesPopover
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              handleClose={() => setAnchorEl(null)}
            />
          )}
        </>
      )}
    />
  );
};
