import { convertArrayToCSV } from 'convert-array-to-csv';
import {
  DatagridConfigurable,
  DateField,
  FunctionField,
  ReferenceField,
  TextField,
} from 'react-admin';
import { saveAs } from '../../../utils/saveAs';
import { ResourcesValue } from '../AdminResources';
import { MoonstarList } from '../Moonstar/MoonstarList';
import { PostFilters } from './PostFilters';
import { PostToolbarActions } from './PostToolbarActions';
import { truncatePost } from './truncatePost';

const exporter = (posts: any) => {
  const mapped = posts.map((p: any) => {
    return {
      Id: p.id,
      Topic: p.topic,
      CreatedOn: p.createdOn,
      AuthorId: p.createdById,
      AuthorInternalID: p.author.internalId,
      Author: p.author.name,
      Department: p.author.department,
      JobTitle: p.author.jobTitle,
      Region: p.author.region,
      Units: p.author.units,
      Title: p.title,
      Content: p.content,
      Views: p.numberOfViews,
      Comments: p.numberOfComments,
      Likes: p.numberOfReactions,
      Badge: p.badge,
      AwardedUser: p.awardedUser,
      AwardedUserId: p.awardedUserId,
      AwardedUserInternalID: p.awardedUserInternalId,
    };
  });

  const result = convertArrayToCSV(mapped, {
    separator: ',',
  });

  saveAs(result, ResourcesValue.POSTS, 'text/csv');
};

export const PostList = () => (
  <MoonstarList
    sort={{ field: 'createdOn', order: 'DESC' }}
    actions={<PostToolbarActions />}
    filters={PostFilters}
    exporter={exporter}
  >
    <DatagridConfigurable
      bulkActionButtons={false}
      omit={['badge', 'awardedUserId']}
    >
      <TextField source="id" />
      <DateField
        source="createdOn"
        showTime={true}
        options={{
          dateStyle: 'medium',
          timeStyle: 'short',
        }}
      />
      <ReferenceField source="createdById" reference="users" label="Author">
        <FunctionField
          render={(record: { firstName: any; lastName: any }) =>
            `${record.firstName} ${record.lastName} `
          }
        />
      </ReferenceField>
      <ReferenceField source="topicId" reference="topics" link={false}>
        <TextField source="displayName" />
      </ReferenceField>
      <FunctionField
        label="Post"
        render={(record: { title?: string; content?: string }) =>
          truncatePost(record)
        }
      />
      <TextField source="numberOfViews" label="Views" sortable={false} />
      <TextField source="numberOfComments" label="Comments" sortable={false} />
      <TextField source="numberOfReactions" label="Likes" sortable={false} />
      <TextField source="badge" label="Badge" sortable={false} />
      <ReferenceField
        source="awardedUserId"
        reference="users"
        label="Awarded User"
      >
        <FunctionField
          render={(record: { firstName: any; lastName: any }) =>
            `${record.firstName} ${record.lastName} `
          }
        />
      </ReferenceField>
    </DatagridConfigurable>
  </MoonstarList>
);
