import {
  AutocompleteInput,
  ReferenceInput,
  TextInput,
  required,
} from 'react-admin';
import { MoonstarEdit } from '../Moonstar/MoonstarEdit';
import { SimpleStack } from '../Moonstar/SimpleStack';
import { MoonstarEditForm } from '../Moonstar/MoonstarEditForm';

export const TopicEdit = () => (
  <MoonstarEdit redirect="list">
    <MoonstarEditForm>
      <SimpleStack>
        <TextInput source="displayName" validate={required()} />
        <ReferenceInput source="formTypeId" reference="formTypes">
          <AutocompleteInput
            optionText="typeName"
            readOnly
            validate={required()}
          />
        </ReferenceInput>
      </SimpleStack>
    </MoonstarEditForm>
  </MoonstarEdit>
);
