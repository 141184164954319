import { SystemStyleObject } from '@mui/system';

export const profilePictureStyles: SystemStyleObject = {
  '& img': {
    maxWidth: 50,
    maxHeight: 50,
    objectFit: 'contain',
    borderRadius: '50%',
  },
};
