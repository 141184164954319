import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useTranslate } from 'react-admin';

type ConfirmDiscardChannelModalProps = {
  open: boolean;
  onClose: () => void;
  onDiscard: () => void;
};

/**
 * Used to confirm discarding a channel when there are no messages in it. It's shown in two cases:
 *  - when the user tries to navigate away from the channel using app's router (different route than chat)
 *  - when the user tries to select different channel from the list of channels without changing the actual route
 */
export const ConfirmDiscardChannelModal = ({
  open,
  onClose,
  onDiscard,
}: ConfirmDiscardChannelModalProps) => {
  const translate = useTranslate();

  return (
    // TODO: Translate all this
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{translate('chat.discardChannelModalTitle')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {translate('chat.discaradChannelModalContent')}
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ padding: '16px' }}>
        <Button onClick={onDiscard} color="primary">
          {translate('chat.discard')}
        </Button>
        <Button variant="contained" onClick={onClose}>
          {translate('chat.stay')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
