import { useDataProvider } from 'react-admin';
import { HttpClient } from '../httpClient';
import { AdminDataProvider } from '../adminDataProvider';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

export const fetchSelectList =
  (httpClient: HttpClient) => async (resource: SelectListResource) => {
    const response = await httpClient.api(`/selectLists/${resource}`);

    return response.json as SelectListDto[];
  };

/**
 * The type of resource to fetch.
 *
 * NOTE: that the API is case-sensitive, so the values must match the API's resource names exactly.
 */
export type SelectListResource =
  | 'Departments'
  | 'JobTitles'
  | 'Regions'
  | 'Units';

export interface SelectListDto {
  id: number;
  name: string;
}

export const useFetchSelectList = (
  resourceType: SelectListResource,
  options?: UseQueryOptions<SelectListDto[], Error, SelectListDto[]>
) => {
  const dataProvider = useDataProvider<AdminDataProvider>();

  return useQuery<SelectListDto[]>({
    queryKey: ['fetchSelectList', resourceType],
    queryFn: () => dataProvider.fetchSelectList(resourceType),
    staleTime: 1000 * 60,
    ...options,
  });
};
