import { Entry } from '../hooks/usePulseQuestionsChartData';
import { HttpClient } from '../httpClient';

export const getPulseQuestionsChartData =
  (httpClient: HttpClient) => async () => {
    const response = await httpClient.api('/admin/pulse/chart', {
      method: 'GET',
    });

    return response.json as Entry[];
  };
