import {
  DateInput,
  ExportButton,
  maxValue,
  minValue,
  SelectArrayInput,
  useGetList,
} from 'react-admin';
import { MoonstarList } from '../Moonstar/MoonstarList';
import { MoonstarListActions } from '../Moonstar/MoonstarListActions';
import { BadgesAwardsChart } from './BadgesAwardsChart';
import { ResourcesValue } from '../AdminResources';
import { Badge } from './types';
import { useState } from 'react';
import { BadgeOptionRenderer } from './BadgeOptionRenderer';
import { CenteredCircularProgress } from '../../custom/CenteredCircularProgress';
import { badgeAwardDateRangeCheck } from '../../../utils/badgeAwardDateRangeCheck';
import { DateInputError } from './DateInputError';
import { BadgesAwardsList } from './BadgesAwardsList';
import { createListExporter } from './createListExporter';

export const BadgesAnalytics = () => {
  const { data, isPending } = useGetList(ResourcesValue.BADGES);
  const badgesList = data as Badge[];

  // Compute default filters
  const now = new Date();
  const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
  const lastDayOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);

  const createdAfterDefault = firstDayOfMonth.toISOString().split('T')[0];
  const createdBeforeDefault = lastDayOfMonth.toISOString().split('T')[0];

  const [createdAfter, setCreatedAfter] = useState<string>(createdAfterDefault);
  const [createdBefore, setCreatedBefore] =
    useState<string>(createdBeforeDefault);
  const [dateRangeError, setDateRangeError] = useState<string>('');

  const handleDateRangeCheck = (
    createdAfter: string,
    createdBefore: string
  ) => {
    badgeAwardDateRangeCheck(createdAfter, createdBefore, setDateRangeError);
  };

  const handleCreatedAfterChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    setCreatedAfter(value);
    handleDateRangeCheck(value, createdBefore);
  };

  const handleCreatedBeforeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    setCreatedBefore(value);
    handleDateRangeCheck(createdAfter, value);
  };

  if (isPending) {
    return <CenteredCircularProgress />;
  }

  const BasicBadgeAwardsFilters = [
    <SelectArrayInput
      source="badgesId"
      choices={badgesList.sort((a, b) => a.id - b.id)}
      optionText={<BadgeOptionRenderer />}
      alwaysOn
      sx={{
        '.MuiInputBase-root': { minHeight: '48px' },
      }}
    />,
    <DateInput
      source="createdAfter"
      label="Awarded from"
      onChange={handleCreatedAfterChange}
      validate={[maxValue(createdBefore)]}
      alwaysOn
      sx={{
        '.MuiInputBase-root': { minHeight: '48px' },
      }}
    />,
    <DateInput
      source="createdBefore"
      label="Awarded before"
      onChange={handleCreatedBeforeChange}
      validate={[minValue(createdAfter)]}
      alwaysOn
      sx={{
        '.MuiInputBase-root': { minHeight: '48px' },
      }}
    />,
  ];

  return (
    <MoonstarList
      resource={ResourcesValue.BADGES_AWARDS}
      filters={BasicBadgeAwardsFilters}
      filterDefaultValues={{
        badgesId: badgesList.flatMap((b) => b.id),
        createdAfter: createdAfterDefault,
        createdBefore: createdBeforeDefault,
      }}
      sort={{ field: 'awardedOn', order: 'DESC' }}
      actions={<MoonstarListActions hideCreate right={<ExportButton />} />}
      exporter={createListExporter(ResourcesValue.BADGES_AWARDS)}
      debounce={1000}
    >
      <DateInputError error={dateRangeError} />

      <BadgesAwardsChart
        title={
          badgesList.length ? 'Selected badges awards' : 'All badges awards'
        }
        name={
          badgesList.length ? 'Selected badges awards' : 'All badges awards'
        }
      />

      <BadgesAwardsList />
    </MoonstarList>
  );
};
