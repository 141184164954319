import { Box, CircularProgress, styled, Typography } from '@mui/material';
import { postPreviewColors } from './PostPreview';
import { useGetCurrentUser } from '../../../../hooks/useGetCurrentUser';
import { PostPreviewHeader } from './PostPreviewHeader';
import { PostPreviewFooter } from './PostPreviewFooter';
import { AttachmentsPreview } from './AttachmentsPreview';
import { ReactAdminEditedFile } from '../../../../api/transformHooks/fileUpload';

export type PollPostPreviewData = {
  topicId: number;
  title: string;
  options: { text: string }[];
  // TODO: fix this type, for poll we are sending only pdf -> single entry to attachments
  attachments?: { src: string }[];
};

type PollPostPreviewProps = {
  topicDisplayName: string;
  previewData: PollPostPreviewData;
};

export const PollPostPreview = ({
  topicDisplayName,
  previewData,
}: PollPostPreviewProps) => {
  const {
    data: currentUser,
    isSuccess: isSuccessCurrentUser,
    isPending,
  } = useGetCurrentUser();

  const currentUserDisplayName =
    isSuccessCurrentUser && `${currentUser.firstName} ${currentUser.lastName}`;

  if (isPending) {
    return (
      <CircularProgress
        color="primary"
        style={{ alignSelf: 'center', backgroundColor: 'transparent' }}
      />
    );
  }

  return (
    <>
      <PostPreviewHeader
        currentUserDisplayName={
          currentUserDisplayName ? currentUserDisplayName : 'You'
        }
        currentUserPictureUrl={currentUser?.pictureUrl ?? ''}
        topicDisplayName={topicDisplayName}
      />

      {/* Post content */}
      <PollOptionsDiv>
        <Typography fontWeight="bold">{previewData.title}</Typography>
        {/* `previewData` could still be data from other forms when user switches them, this ensures that data transform to poll form is awaited */}
        {Array.isArray(previewData.options) &&
          previewData.options.map((option, index) =>
            option.text ? (
              <PollOptionBox border={0.8} key={`${option.text}-${index}`}>
                <Typography color={postPreviewColors.pollOption}>
                  {option.text}
                </Typography>
              </PollOptionBox>
            ) : null
          )}
      </PollOptionsDiv>

      {/* Attachments */}
      {previewData.attachments && (
        <AttachmentsPreview
          attachments={
            [previewData.attachments] as unknown as ReactAdminEditedFile[]
          }
        />
      )}

      {/* Reactions and comments */}
      <PostPreviewFooter />
    </>
  );
};

const PollOptionsDiv = styled('div')(() => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
}));

const PollOptionBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  maxWidth: '90%',
  justifyContent: 'left',
  padding: theme.spacing(1),
  marginTop: theme.spacing(1),
  borderRadius: 8,
  borderColor: postPreviewColors.pollOptionBorderNoVote,
  backgroundColor: postPreviewColors.pollOptionBackground,
  overflow: 'hidden',
}));
