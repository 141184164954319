import { getCommonDefaultValues } from '../CommonDefaultValues';
import { Post } from './PostSchema';

export const getPostDefaultValues = (
  defaultTopicId: number | undefined
): Post => ({
  ...getCommonDefaultValues(),
  topicId: defaultTopicId ?? 0, // Zod schema marks the field as required, but it's undefined whilst loading
  content: '',
  attachments: [],
});
