import { useDataProvider } from 'react-admin';
import { HttpClient } from '../httpClient';
import { AdminDataProvider } from '../adminDataProvider';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

export const getUsersChartData =
  (httpClient: HttpClient) => async (data: any) => {
    const response = await httpClient.api('/admin/users/chart', {
      method: 'POST',
      body: JSON.stringify(data),
    });

    return response.json as Entry[];
  };

export interface ChartParams {
  createdAfter: string;
  createdBefore: string;
}

export interface Entry {
  date: string;
  count: number;
  percentage: number;
}

export const useUsersChartData = (
  params: ChartParams,
  options?: UseQueryOptions<Entry[], Error, Entry[]>
) => {
  const dataProvider = useDataProvider<AdminDataProvider>();

  return useQuery<Entry[]>({
    queryKey: ['getUsersChartData', params],
    queryFn: () => dataProvider.getUsersChartData(params),
    staleTime: 1000 * 60,
    ...options,
  });
};
