import { z } from 'zod';

export const MAX_NUMBER_OF_CHARS_IN_CONTENT = 5000;
export const MIN_NUMBER_OF_CHARS_IN_CONTENT = 3;

export const AttachmentSchema = z.object({
  src: z.string(),
  title: z.string().optional(),
  rawFile: z.instanceof(File),
});

export const ContentSchema = z.object({
  content: z
    .string({
      message: "Content field can't be empty.",
    })
    .trim()
    .max(MAX_NUMBER_OF_CHARS_IN_CONTENT, {
      message: `Content must be at most ${MAX_NUMBER_OF_CHARS_IN_CONTENT} characters long.`,
    })
    .min(MIN_NUMBER_OF_CHARS_IN_CONTENT, {
      message: `Content must be at least ${MIN_NUMBER_OF_CHARS_IN_CONTENT} characters long.`,
    }),
});

export const AdvancedSettingsSchema = z.object({
  publishingDate: z.string(),
  allowComments: z.boolean(),
  allowReactions: z.boolean(),
  isImportant: z.boolean(),
});

export const VisibilitySchema = z.object({
  visibleForEveryone: z.boolean(),
  departments: z.array(z.number()),
  jobTitles: z.array(z.number()),
  regions: z.array(z.number()),
  units: z.array(z.number()),
});

export const TaggedUsersSchema = z.object({
  taggedUsers: z.array(z.number()),
});

export const PDFSchema = z
  .object({
    name: z.string().optional(),
    url: z.string(),
  })
  .optional();

export type AdvancedSettings = z.infer<typeof AdvancedSettingsSchema>;
export type Visibility = z.infer<typeof VisibilitySchema>;
// This is not used in the project yet
export type TaggedUsers = z.infer<typeof TaggedUsersSchema>;

export type PDF = z.infer<typeof PDFSchema>;
