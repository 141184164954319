import { useNotify, useTranslate } from 'react-admin';
import { useUserRoleContext } from '../../../../auth/userRoleContext';
import { useCallback, useState } from 'react';
import GroupChannelListHeader from '@sendbird/uikit-react/GroupChannelList/components/GroupChannelListHeader';
import { IconButton } from '@sendbird/uikit-react/ui/Header';
import { IconColors, IconTypes } from '@sendbird/uikit-react/ui/Icon';
import { SelectCreateGroupsModal } from './SelectCreateGroupsModal';
import { CreateGroupsModal } from './CreateGroupsModal';
import { useCreateChannelContext } from '@sendbird/uikit-react/CreateChannel/context';
import { CHANNEL_TYPE } from './types';

export const GroupChannelListCustomHeader = () => {
  const notification = useNotify();
  const translate = useTranslate();
  const { userRole } = useUserRoleContext();
  const { setType } = useCreateChannelContext();
  const [openCreateGroups, setOpenCreateGroups] = useState(false);
  const [openSelectGroupType, setOpenSelectGroupType] = useState(false);

  const isAdmin = userRole === 'Admin';

  // Also check roles before opening modal, so we show notification about error before we show modal if there's one
  const handleCreateChannel = useCallback(() => {
    if (isAdmin) {
      setOpenSelectGroupType(true);
    } else if (userRole === 'User') {
      // If user is not an admin, skip the select group type modal and create normal, non-supergroup channel
      setType(CHANNEL_TYPE.GROUP as any);
      setOpenCreateGroups(true);
    } else {
      notification(translate('chat.badUserRole'), {
        type: 'error',
      });
    }
  }, [userRole, isAdmin, notification, setType, translate]);

  return (
    <>
      <GroupChannelListHeader
        renderRight={() => (
          <IconButton
            type={IconTypes.CREATE}
            color={IconColors.PRIMARY}
            onClick={handleCreateChannel}
          />
        )}
      />
      {isAdmin && (
        <SelectCreateGroupsModal
          open={openSelectGroupType}
          onClose={() => setOpenSelectGroupType(false)}
        />
      )}
      {!isAdmin && (
        <CreateGroupsModal
          open={openCreateGroups}
          onClose={() => setOpenCreateGroups(false)}
        />
      )}
    </>
  );
};
