import '@sendbird/uikit-react/dist/index.css';
import { FC } from 'react';
import { AdminContext, AdminProps } from 'react-admin';
import { UserRoleProvider } from '../auth/userRoleContext';
import { AdminResources } from '../components/admin/AdminResources';
import { useTenantId } from '../components/OrganizationGuard';
import { useGetCurrentUser } from '../hooks/useGetCurrentUser';
import { useInitMonitoring } from '../hooks/useInitMonitoring';
import { useUserSessionTracking } from '../utils/monitoringIdentify';
import { SendbirdProviderWithSDK } from '../components/admin/Chat/SendbirdProviderWithSDK';

export const Admin: FC<AdminProps> = (props) => {
  const { data: user } = useGetCurrentUser();
  const tenantId = useTenantId();

  useInitMonitoring();
  useUserSessionTracking(user, tenantId);

  return (
    <AdminContext {...props}>
      <UserRoleProvider>
        <SendbirdProviderWithSDK>
          <AdminResources {...props} />
        </SendbirdProviderWithSDK>
      </UserRoleProvider>
    </AdminContext>
  );
};
