import { Avatar, styled, Typography } from '@mui/material';

export const PostAvatar = styled(Avatar)(() => ({}));

export const PostHeaderDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  gap: theme.spacing(1),
}));

export const PostContentDiv = styled('div')(() => ({
  display: 'flex',
  flex: 1,
  maxWidth: '100%',
  overflow: 'hidden',
  flexWrap: 'wrap',
}));

export const PostAttachmentGroupMedia = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  overflowX: 'scroll',
  scrollbarWidth: 'none',
}));

export const PostAttachmentPdf = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: '#EFEEEA',
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  borderRadius: 4,
  fontWeight: 'bold',
}));

export const PostMenuAndDateDiv = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  marginLeft: 'auto',
}));

export const PostReactionAndCommentsDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
}));

export const RecognitionDiv = styled('div')(({ theme }) => ({
  gap: theme.spacing(1),
  flexWrap: 'wrap',
  alignItems: 'center',
  alignSelf: 'center',
  textAlign: 'center',
}));

export const BadgeImg = styled('img')(({ theme }) => ({
  height: 64,
  width: 64,
  alignSelf: 'center',
  marginBottom: theme.spacing(1),
}));

export const CenteredTypography = styled(Typography)(() => ({
  display: 'block',
  textAlign: 'center',
}));

export const InlineTextWrapper = styled('div')(() => ({
  textAlign: 'center',
  display: 'inline-block',
  wordWrap: 'break-word',
  whiteSpace: 'normal',
}));
