import { HttpError } from 'react-admin';
import { HttpClient } from '../httpClient';

type FileToUpload = {
  /**
   * The file name with extension, e.g. 'Invoice-2929-01-06.pdf'
   */
  title: string;
  /**
   * The {@link File} object
   */
  rawFile: File;
};

export type FileHostType = 'images' | 'public' | 'private';

type UploadForm = {
  fileFieldName: string;
  otherFields: Record<string, string>;
};

type UploadResponse = {
  uploadTo: {
    url: string;
    method: string;
    form: UploadForm;
  };
  downloadFrom: string;
};

export const uploadFile =
  (httpClient: HttpClient) =>
  async (request: FileToUpload, hostType: FileHostType) => {
    let fileId = crypto.randomUUID();
    const fileExt = request.title.substring(request.title.lastIndexOf('.'));
    fileId = `${fileId}${fileExt}`;

    const getUploadEndpointPath = `/${hostType}/${fileId}`;

    const uploadResRaw = await httpClient.fileHost(getUploadEndpointPath, {
      method: 'POST',
    });

    const uploadRes = JSON.parse(uploadResRaw.body) as UploadResponse;

    const res = await fetch(uploadRes.uploadTo.url, {
      method: uploadRes.uploadTo.method,
      body: getUploadFormData(request.rawFile, uploadRes.uploadTo.form),
    });

    if (res.status < 200 || res.status >= 300) {
      throw new HttpError(
        'Uploading file failed',
        res.status,
        await res.text()
      );
    }

    return uploadRes.downloadFrom;
  };

const getUploadFormData = (file: File, form: UploadForm): FormData => {
  const formData = new FormData();
  Object.entries(form.otherFields).map(([name, value]) => {
    formData.append(name, value);
  });
  formData.append(form.fileFieldName, file);

  return formData;
};
