import ChannelSettingsUI from '@sendbird/uikit-react/ChannelSettings/components/ChannelSettingsUI';
import { CustomModerationPanel } from './CustomModerationPanel';

export const GroupCustomChannelSettingsUI = () => {
  return (
    <ChannelSettingsUI
      renderModerationPanel={() => <CustomModerationPanel />}
    />
  );
};
