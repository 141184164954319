import polyglotI18nProvider from 'ra-i18n-polyglot';
import { Translations } from './Translations';
import { englishTranslations } from './englishTranslations';

const translations: Record<string, Translations> = {
  en: englishTranslations,
};

// TODO: use resolveBrowserLocale() instead of 'en' once 'ro' is added

export const i18nProvider = polyglotI18nProvider(
  (locale) => translations[locale],
  'en',
  [
    {
      locale: 'en',
      name: 'English',
    },
  ]
);
