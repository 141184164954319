import { useDataProvider } from 'react-admin';
import { AdminDataProvider } from '../api/adminDataProvider';
import { useQuery, queryOptions } from '@tanstack/react-query';

const fetchAllUsersOptions = (provider: AdminDataProvider) =>
  queryOptions({
    queryKey: ['allUsers'],
    queryFn: provider.fetchAllUsers,
  });

export const useGetAllUsers = (
  options: Partial<ReturnType<typeof fetchAllUsersOptions>> = {}
) => {
  const provider = useDataProvider<AdminDataProvider>();

  return useQuery({
    ...fetchAllUsersOptions(provider),
    ...options,
  });
};
