import { H } from 'highlight.run';
import { User } from '../components/admin/User/types';
import { useEffect } from 'react';

/**
 * Enable searching by user info in the highlight.io dashboard
 */
export const useUserSessionTracking = (
  user: User | undefined,
  tenantId: string
) => {
  useEffect(() => {
    if (!user) {
      return;
    }

    const identifier = user.phoneNumber ?? user.email ?? user.id.toString();

    H.identify(identifier, {
      id: user.id.toString(),
      phone: user.phoneNumber ?? '',
      email: user.phoneNumber ?? '',
      tenantId,
    });
  }, [user, tenantId]);
};
