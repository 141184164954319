import { FC } from 'react';
import { useDataProvider } from 'react-admin';
import { AdminDataProvider } from '../../../../api/adminDataProvider';
import { CustomBulkAction } from '../../../custom/CustomBulkAction';
import { ResourcesValue } from '../../AdminResources';
import { GroupRemoveOutlined } from '@mui/icons-material';

export const BulkRemoveCohortMembers: FC<{ cohortId: number }> = (props) => {
  const dataProvider = useDataProvider<AdminDataProvider>();

  return (
    <CustomBulkAction
      mutationFn={(userIds) =>
        dataProvider.removeCohortMembers({
          data: userIds.map((userId) => ({
            cohortId: props.cohortId,
            userId,
          })),
        })
      }
      resource={ResourcesValue.COHORT_MEMBERSHIPS}
      label="ra.action.remove"
      color="error"
      buttonIcon={<GroupRemoveOutlined />}
      successMessage="moonstar.cohortMemberships.remove.successMessage"
    />
  );
};
