import { CircularProgress, CircularProgressProps } from '@mui/material';

export const CenteredCircularProgress = ({
  size,
  ...rest
}: CircularProgressProps) => (
  <div
    style={{
      display: 'flex',
      width: '100%',
      height: '100%',
      alignContent: 'center',
      justifyContent: 'center',
    }}
  >
    <CircularProgress
      size={size}
      color="primary"
      style={{ alignSelf: 'center', backgroundColor: 'transparent' }}
      {...rest}
    />
  </div>
);
