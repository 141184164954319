import { useSendbirdContext } from '../../../components/admin/Chat/SendbirdProviderWithSDK';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';
import { GroupChannelHandler } from '@sendbird/chat/groupChannel';
import { v4 as uuid } from 'uuid';

export const useGetTotalUnreadMessageCount = () => {
  const { sb } = useSendbirdContext();
  const queryClient = useQueryClient();
  const queryKey = useMemo(() => ['total-unread-message-count'], []);
  const unreadMessageHandlerKey = useMemo(() => uuid(), []);

  useEffect(() => {
    if (!sb?.groupChannel) {
      return;
    }

    const handler = new GroupChannelHandler({
      onUnreadMemberStatusUpdated: () => {
        void queryClient.invalidateQueries({ queryKey });
      },
      onMessageReceived: () => {
        void queryClient.invalidateQueries({ queryKey });
      },
      onChannelChanged: () => {
        void queryClient.invalidateQueries({ queryKey });
      },
    });

    sb.groupChannel.addGroupChannelHandler(unreadMessageHandlerKey, handler);

    return () => {
      sb.groupChannel.removeGroupChannelHandler(unreadMessageHandlerKey);
    };
  }, [sb, queryClient, unreadMessageHandlerKey, queryKey]);

  return useQuery({
    queryKey,
    queryFn: async () => {
      if (!sb?.groupChannel) {
        return 0;
      }

      try {
        const unreadCount = await sb.groupChannel.getTotalUnreadMessageCount();
        return unreadCount;
      } catch (error) {
        console.error(error);
        return 0;
      }
    },
    staleTime: 10_000,
  });
};
