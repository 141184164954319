import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
} from 'react';
import { Loading } from 'react-admin';
import { useUserOrganizations } from '../hooks/useUserOrganizations';

/**
 * Forces an organization to be selected before rendering children.
 *
 * - If an organization is not already selected, one will be selected automatically.
 * - If the user is not in any organizations, an error will be shown instead.
 */
export const OrganizationGuard: FC<PropsWithChildren> = (props) => {
  const {
    lastUsedOrganization,
    organizations,
    activeOrganization,
    setActiveOrganization: setActive,
    user,
  } = useUserOrganizations();

  useEffect(() => {
    if (activeOrganization) return;
    if (!user) return;
    if (!setActive) return;

    if (lastUsedOrganization) {
      // Default to the last used organization
      void setActive({
        organization: lastUsedOrganization.id,
      });
    } else if (organizations.length > 0) {
      // Default to the 1st (oldest) organization
      const newOrgId = organizations[0].organization.id;

      void setActive({
        organization: newOrgId,
      });
    }
  }, [
    lastUsedOrganization,
    user,
    organizations,
    setActive,
    activeOrganization,
  ]);

  if (!user || !activeOrganization) {
    return (
      <div style={{ margin: '1rem' }}>
        <Loading />
      </div>
    );
  }

  if (organizations.length === 0) {
    return (
      <div style={{ margin: '1rem' }}>
        No organizations detected. Please contact support to be added to an
        organization
      </div>
    );
  }

  if (!activeOrganization.organization.publicMetadata.tenantId) {
    return (
      <div style={{ margin: '1rem' }}>
        No tenant ID found for the current organization. Please contact support.
      </div>
    );
  }

  return (
    <tenantIdContext.Provider
      value={activeOrganization.organization.publicMetadata.tenantId}
    >
      {props.children}
    </tenantIdContext.Provider>
  );
};

const tenantIdContext = createContext<string | null>(null);

export const useTenantId = () => {
  const tenantId = useContext(tenantIdContext);

  if (!tenantId) {
    throw new Error('useTenantId must be used within an OrganizationGuard');
  }

  return tenantId;
};
