import { FC } from 'react';
import { DeleteButton, SaveButton, Toolbar, ToolbarProps } from 'react-admin';

export type MoonstarEditFormToolbarProps = ToolbarProps & {
  hideDelete?: boolean;
};

export const MoonstarEditFormToolbar: FC<MoonstarEditFormToolbarProps> = (
  props
) => {
  return (
    <Toolbar {...props}>
      <div className="RaToolbar-defaultToolbar">
        <SaveButton />
        {props.hideDelete ? undefined : (
          <DeleteButton mutationMode="pessimistic" />
        )}
      </div>
    </Toolbar>
  );
};
