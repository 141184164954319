import SendIcon from '@mui/icons-material/Send';
import { Alert, CircularProgress } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { FC, useCallback } from 'react';
import {
  Button,
  useDataProvider,
  useListContext,
  useNotify,
  useTranslate,
  useUnselectAll,
} from 'react-admin';
import { AdminDataProvider } from '../../../api/adminDataProvider';
import { ResourcesValue } from '../AdminResources';
import { SmsMessage } from './types';

export const SmsMessageResendAction: FC = () => {
  const { selectedIds, refetch } = useListContext<SmsMessage>();
  const dataProvider = useDataProvider<AdminDataProvider>();
  const translate = useTranslate();
  const notify = useNotify();
  const unselectAll = useUnselectAll(ResourcesValue.SMS);

  const resend = useMutation({
    mutationFn: dataProvider.resendWelcomeSms,
    onSuccess: (e) => {
      if (e.failedIds.length === 0) {
        notify(
          <Alert severity="success">
            {translate('moonstar.sms_message.success', {
              smart_count: e.successfulIds.length,
            })}
          </Alert>
        );
      } else {
        notify(
          <Alert severity="warning">
            {translate('moonstar.sms_message.partial_success', {
              total_success: e.successfulIds.length,
              total_failed: e.failedIds.length,
            })}
          </Alert>
        );
      }
    },
    onError: () => {
      notify(
        <Alert severity="error">
          {translate('moonstar.sms_message.error')}
        </Alert>,
        {
          autoHideDuration: 5000,
        }
      );
    },
    onSettled: () => {
      void refetch();
      unselectAll();
    },
  });

  const resendSmsMessages = useCallback(() => {
    resend.mutate({ ids: selectedIds });
  }, [selectedIds, resend]);

  if (resend.isPending) {
    return <CircularProgress size="1.5em" />;
  }
  return (
    <Button
      onClick={resendSmsMessages}
      color="success"
      startIcon={<SendIcon />}
      label="moonstar.sms_message.resend"
    />
  );
};
