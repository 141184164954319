import { FC } from 'react';
import {
  useResourceContext,
  useGetResourceLabel,
  List,
  ListProps,
  useTranslate,
} from 'react-admin';
import { MoonstarListActions } from './MoonstarListActions';
import { MoonstarHelmet } from './MoonstarHelmet';

export const MoonstarList: FC<ListProps> = (props) => {
  const resourceContext = useResourceContext();
  const getLabel = useGetResourceLabel();
  const translate = useTranslate();
  const appName = translate('moonstar.appName');

  const label = getLabel(resourceContext as string, 2);

  return (
    <List
      actions={<MoonstarListActions />}
      title={`${appName} / ${label}`}
      {...props}
    >
      <MoonstarHelmet>
        <title>
          {label} | {appName}
        </title>
      </MoonstarHelmet>
      {props.children}
    </List>
  );
};
