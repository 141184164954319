import { radiantLightTheme } from 'react-admin';
import { ThemeOptions } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';

const moonstarColor = '#28808F';
const boxShadow =
  '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)';

/**
 * The main app theme. To use the theme in a component, use `useTheme` from `@mui/material`
 * @see https://marmelab.com/react-admin/Admin.html#theme
 * @see https://mui.com/material-ui/customization/theme-components/
 */
export const MOONSTAR_THEME = deepmerge(radiantLightTheme, {
  // If you're adding new custom properties, make sure to add them to the ThemeOptions interface in types.d.ts
  moonstar: {
    charts: {
      palette: {
        color1: '#28808F',
        color2: '#3E4B63',
        color3: '#a2b6df',
      },
    },
  },
  palette: {
    primary: {
      main: moonstarColor,
      light: moonstarColor,
      dark: moonstarColor,
    },
    secondary: {
      main: moonstarColor,
      light: moonstarColor,
      dark: moonstarColor,
    },
  },
  components: {
    RaBulkActionsToolbar: {
      styleOverrides: {
        root: {
          '& .RaBulkActionsToolbar-toolbar': {
            backgroundColor: '#c9e5e9',
          },
        },
      },
    },
    RaList: {
      styleOverrides: {
        root: {
          '& .RaList-content': {
            boxShadow: boxShadow,
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          boxShadow: boxShadow,
        },
      },
    },
    RaCreate: {
      styleOverrides: {
        root: {
          '& .RaCreate-card': {
            boxShadow: boxShadow,
          },
        },
      },
    },
    RaEdit: {
      styleOverrides: {
        root: {
          '& .RaEdit-card': {
            boxShadow: boxShadow,
          },
        },
      },
    },
    RaDatagrid: {
      styleOverrides: {
        root: {
          '& .RaDatagrid-headerCell': {
            color: moonstarColor,
          },
          '& .RaDatagrid-root': {
            marginTop: '0px',
          },
        },
      },
    },
    RaMenuItemLink: {
      styleOverrides: {
        root: {
          '&.RaMenuItemLink-active': {
            borderLeft: `3px solid ${moonstarColor}`,
            borderRadius: '0px 100px 100px 0px',
            backgroundImage: `linear-gradient(98deg, ${moonstarColor}, ${moonstarColor} 94%)`,
            boxShadow:
              '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)',
            color: '#fff',
          },
        },
      },
    },
  },
}) satisfies ThemeOptions;
