import {
  AutocompleteArrayInput,
  DateTimeInput,
  ImageField,
  ImageInput,
  NumberInput,
  ReferenceArrayInput,
  TextInput,
  required,
} from 'react-admin';
import { SimpleStack } from '../Moonstar/SimpleStack';
import { MoonstarEditForm } from '../Moonstar/MoonstarEditForm';
import { MoonstarEdit } from '../Moonstar/MoonstarEdit';
import { MoonstarEditFormToolbar } from '../Moonstar/MoonstarEditFormToolbar';
import { maxFileSize } from '../../../utils/validation';

const imageValidation = maxFileSize('5MB');

export const NudgeEdit = () => (
  <MoonstarEdit redirect="list">
    <MoonstarEditForm toolbar={<MoonstarEditFormToolbar hideDelete />}>
      <SimpleStack>
        <ImageInput
          source="image"
          accept={{ 'image/*': ['.jpg', '.jpeg', '.png', '.gif'] }}
          sx={{ alignItems: 'start' }}
          validate={imageValidation}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
        <TextInput source="title" validate={required()} />
        <ReferenceArrayInput
          source="categoryIds"
          reference="nudgeCategories"
          perPage={999}
          sort={{ field: 'name', order: 'ASC' }}
        >
          <AutocompleteArrayInput margin="none" optionText="name" />
        </ReferenceArrayInput>
        <ReferenceArrayInput
          source="cohortIds"
          reference="cohorts"
          perPage={999}
          sort={{ field: 'name', order: 'ASC' }}
        >
          <AutocompleteArrayInput
            margin="none"
            optionText="name"
            helperText="Select which cohorts to show this nudge to"
          />
        </ReferenceArrayInput>
        <TextInput source="description" />
        <NumberInput source="duration" step={1} min={1} validate={required()} />
        <TextInput source="hintDescription" validate={required()} />
        <DateTimeInput source="hintValidTillDateTime" validate={required()} />
      </SimpleStack>
    </MoonstarEditForm>
  </MoonstarEdit>
);
