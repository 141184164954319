type FileSizeUnit = 'B' | 'KB' | 'MB' | 'GB' | 'TB';

export type FileSize = `${number}${FileSizeUnit}`;

const BYTES_MULTIPLIER = {
  B: 1,
  KB: 1_000,
  MB: 1_000_000,
  GB: 1_000_000_000,
  TB: 1_000_000_000_000,
} as const;

/**
 * Converts to bytes from a human-readable file size string
 * @param fileSize The human-readable file size string
 * @example
 * ```ts
 * toBytes('5MB'); // 5_000_000
 * ```
 */
export const toBytes = (fileSize: FileSize) => {
  let unit: FileSizeUnit;
  if (fileSize.endsWith('TB')) {
    unit = 'TB';
  } else if (fileSize.endsWith('GB')) {
    unit = 'GB';
  } else if (fileSize.endsWith('MB')) {
    unit = 'MB';
  } else if (fileSize.endsWith('KB')) {
    unit = 'KB';
  } else {
    unit = 'B';
  }

  const amount = parseFloat(fileSize.slice(0, -unit.length));
  return amount * BYTES_MULTIPLIER[unit];
};
