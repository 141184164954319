import { Button, Datagrid, ImageField, TextField } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import { MoonstarList } from '../Moonstar/MoonstarList';
import { MoonstarListActions } from '../Moonstar/MoonstarListActions';
import { ResourcesValue } from '../AdminResources';

export const BadgesList = () => (
  <MoonstarList actions={<BadgesListActions />}>
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <ImageField label="Badge image" source="imageUrl" />
      <TextField source="name" />
    </Datagrid>
  </MoonstarList>
);

const BadgesListActions = () => {
  const navigate = useNavigate();

  return (
    <MoonstarListActions
      sx={{ flex: 1 }}
      left={
        <Button
          sx={{ marginRight: 'auto' }}
          label="Analytics"
          onClick={() => navigate(`/${ResourcesValue.BADGES_AWARDS}/analytics`)}
        />
      }
    />
  );
};
