import { Datagrid, TextField } from 'react-admin';
import { MoonstarList } from '../Moonstar/MoonstarList';
import { MoonstarDatagridBulkActions } from '../Moonstar/MoonstarDatagridBulkActions';

export const DepartmentList = () => {
  return (
    <MoonstarList>
      <Datagrid
        rowClick="edit"
        bulkActionButtons={<MoonstarDatagridBulkActions />}
      >
        <TextField source="name" />
      </Datagrid>
    </MoonstarList>
  );
};
