import {
  CreateButton,
  ExportButton,
  FilterButton,
  SelectColumnsButton,
  TopToolbar,
} from 'react-admin';

export const PostToolbarActions = () => {
  return (
    <TopToolbar>
      <SelectColumnsButton />
      <FilterButton />
      <ExportButton />
      <CreateButton />
    </TopToolbar>
  );
};
